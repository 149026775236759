export {default as BaseStore} from './BaseStore'
export {default as DirectoryStore} from './DirectoryStore'
export {default as EventStore} from './EventStore'
export {default as FacultyStore} from './FacultyStore'
export {default as LocationStore} from './LocationStore'
export {default as RoomStore} from './RoomStore'
export {default as ScreenStore} from './ScreenStore'
export {default as UIStore} from './UIStore'
import RootStore from './RootStore'
export {RootStore}

const store = new RootStore()

export async function initializeStores() {
  const stores = {
    DirectoryStore: store.directoryStore,
    FacultyStore: store.facultyStore,
    EventStore: store.eventStore,
    LocationStore: store.locationStore,
    RoomStore: store.roomStore,
    UIStore: store.uiStore,
    ScreenStore: store.screenStore
  }
  ;(window as any).stores = stores
}

initializeStores()

// default export is an initialized instance of RootStore with substores
export default store
