import {observable, computed} from 'mobx'

import {Dir_Screen} from 'api/interfaces'
import Screen from 'models/Screen'
import BaseStore, {FuseResult} from './BaseStore'

export default class ScreenStore extends BaseStore<Screen> {
  @observable screenID: string | null = null

  @computed
  get screens() {
    return Array.from(this.data.values())
  }

  @computed
  get currentScreen() {
    return this.data.get(this.screenID) || null
  }

  search = (): FuseResult<Screen>[] => []

  add = (attrs: Dir_Screen) => {
    const newScreen = new Screen(this.root, attrs)

    this.data.set(newScreen.id, newScreen)

    return newScreen
  }

  process = (screenData: Dir_Screen[]) => {
    return screenData.map(s => this.add(s))
  }
}
