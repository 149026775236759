import * as React from 'react'
import {inject} from 'mobx-react'
import {withRouter, RouteComponentProps} from 'react-router'
import {Button} from 'react-bootstrap'

import RoomStore from 'stores/RoomStore'
import {Event, Faculty, Room} from 'models'

interface MatchParams {
  dirID: string
}

interface Props extends RouteComponentProps<MatchParams> {
  className?: string
  thing: Event | Faculty | Room
  RoomStore?: RoomStore
}

@inject('RoomStore')
class TakeMeThere extends React.Component<Props> {
  get room() {
    if (!this.props.thing) return null
    if (this.props.thing instanceof Room) return this.props.thing
    return this.props.RoomStore.get(this.props.thing.room_id)
  }

  goto = () => {
    const room = this.room

    let locID: string
    if (!room.dir_location_ids.length) {
      const err =
        'Room has no associated location ID(s). Intended ' +
        `destination room name is "${room.name}"`
      alert(err)
      console.warn(err, room)
      locID = ''
    } else locID = room.dir_location_ids[0]

    this.props.history.push(
      `/touch/directory/${room.directory_id}/floormap/${locID}`
    )
  }

  render() {
    if (!this.room) return null

    return (
      <Button
        bsSize="large"
        className={`take-me-there ${this.props.className}`}
        onClick={this.goto}
      >
        TAKE ME THERE
      </Button>
    )
  }
}

export default withRouter(TakeMeThere)
