import * as React from 'react'
import {AxiosError} from 'axios'
import {inject} from 'mobx-react'
import {withRouter, RouteComponentProps} from 'react-router'

import DirectoryStore from 'stores/DirectoryStore'
import UIStore from 'stores/UIStore'
import Directory from 'models/Directory'

interface MatchParams {
  dirID: string
}

interface Props extends RouteComponentProps<MatchParams> {
  DirectoryStore: DirectoryStore
  UIStore: UIStore
}

interface State {
  currentDirectory: Directory
  message: string
}

interface InnerProps {
  directory: Directory
}

/** DirectoryWrapper Higher-Order Component
 *  Loads the directory if it is not loaded, and change global currentDirectory.
 *  Side-effects:
 *    - injects 'DirectoryStore' and 'directory' props
 */
export default function withDirectoryWrapper<P extends InnerProps>(
  WrappedComponent: React.ComponentType<P>
) {
  const componentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  /** The Higher-Order Component */
  @inject('DirectoryStore', 'UIStore')
  class DirectoryWrapper extends React.Component<P & Props, State> {
    static displayName: string = `WithDirectoryWrapper(${componentName})`

    constructor(props: P & Props) {
      super(props)

      this.state = {
        message: `Loading ${DirectoryWrapper.displayName}...`,
        currentDirectory: null
      }
    }

    componentDidUpdate(prevProps: Props) {
      if (prevProps.match.params.dirID !== this.props.match.params.dirID)
        this.load()
    }

    componentDidMount() {
      this.load()
    }

    load = () => {
      const dirID = this.props.match.params.dirID
      // catch non-numeric directory "ids"
      if (isNaN(parseInt(dirID)) || +dirID < 1) {
        this.errorHandler(dirID, 'Bad Directory ID')
        // this.props.history.push('/touch')
        return
      }

      this.props.UIStore.globalLoading++
      this.props.DirectoryStore.loadDirectory(dirID)
        .then(directory => {
          // only runs if loadDirectory was successful
          this.setState({currentDirectory: directory, message: null})
        })
        .catch(this.errorHandler.bind(this, dirID))
        .then(pass => {
          this.props.UIStore.globalLoading--
          return pass
        })
    }

    errorHandler = (dirID: string, reason: Error | AxiosError | string) => {
      console.error(`Directory.load: dirID=${dirID}, reason=`, [reason])
      let message = 'unknown error'

      if (!reason) {
      } // skip if statement
      else if (typeof reason == 'string') message = reason
      else if ('response' in reason) message = reason.message

      this.setState({
        currentDirectory: null,
        message: `Failed to load directory ${dirID}; ${message}`
      })
    }

    render() {
      if (!this.state.currentDirectory)
        return (
          <div className="error-directory-wrapper">{this.state.message}</div>
        )

      return (
        <WrappedComponent
          directory={this.state.currentDirectory}
          {...this.props}
        />
      )
    }
  }

  return DirectoryWrapper
}
