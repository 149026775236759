import {computed} from 'mobx'
import * as Fuse from 'fuse.js'

import {Dir_Person} from 'api/interfaces'
import Faculty from 'models/Faculty'
import Directory from 'models/Directory'
import BaseStore, {FuseResult} from './BaseStore'

export default class FacultyStore extends BaseStore<Faculty> {
  static readonly _searchOptions = Object.freeze(
    Object.assign(
      {
        keys: ['title', 'position', 'name', 'display_name', 'email', 'type']
      },
      BaseStore._searchOptions
    )
  )

  @computed
  get fuse() {
    return new Fuse(this.faculty, FacultyStore._searchOptions)
  }

  @computed
  get faculty() {
    return Array.from(this.data.values())
  }

  search = (inputValue: string): FuseResult<Faculty>[] => {
    return this.fuse.search(inputValue)
  }

  add = (attrs: Dir_Person) => {
    const newFaculty = new Faculty(this.root, attrs)

    this.data.set(newFaculty.id, newFaculty)

    return newFaculty
  }

  process = (data: Dir_Person[]) => {
    for (const faculty of data) {
      this.add(faculty)
    }
  }

  addToFaculty = (faculty: Faculty, directory: Directory) => {
    faculty.directory_id = directory.id
    directory.faculty.push(faculty)
  }
}
