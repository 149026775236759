import {observable, IObservableArray, computed} from 'mobx'
import debounce = require('lodash.debounce')

import {FuseResult, SearchableType} from 'stores/BaseStore'
import RootStore from 'stores/RootStore'

const ACCESSIBLE_CLASS = 'accessible'
type ObservableSearchableFuse = IObservableArray<FuseResult<SearchableType>>

type PLATFORM = '' | 'ios' | 'android'

const TIME_RE = /^((\d+)m)?(\d+)s?$/i

const CHANGE_DELAY = 750

function platformFinder(): PLATFORM {
  const UA = navigator.userAgent || navigator.vendor
  if ((window as any).MSStream || /windows phone/i.test(UA)) return ''
  if (/ipad|iphone|ipod/i.test(UA)) return 'ios'
  if (/android/i.test(UA)) return 'android'
  return ''
}

function isPositiveQueryArg(value: string | undefined) {
  return value === undefined || value === 'true' || value === 'on'
}

export default class UIStore {
  @observable searchTerm: string = ''
  @observable searchResults: ObservableSearchableFuse = observable.array()
  @observable rightClickDisabled = false
  @observable inactivityTimeout = 0 // timeout in milliseconds
  @observable useOnScreenKeyboard = false
  @observable keyboardIsVisible = false
  @observable keyboardYPosition = window.innerHeight - 389
  @observable searchBarFunct: () => void = null
  @observable platform = platformFinder()
  /// if > 0, we're loading content
  @observable globalLoading: number = 0
  root: RootStore

  constructor(root: RootStore) {
    this.root = root

    this.setOrientation()
    window.addEventListener('orientationchange', this.setOrientation)
    window.addEventListener('resize', this.setOrientation)
  }

  @computed
  get searchResultIDs() {
    return this.searchResults.map(res => res.item.id)
  }

  @computed
  get directoryDynamicStyles() {
    const {currentDirectory} = this.root.directoryStore
    return currentDirectory ? currentDirectory.dynamicStyles : null
  }

  @computed
  get directoryImage() {
    const {currentDirectory} = this.root.directoryStore
    return currentDirectory ? currentDirectory.image : null
  }

  get isAccessible() {
    // NOT computable
    const html = document.getElementsByTagName('html')[0]
    return html.classList.contains(ACCESSIBLE_CLASS)
  }

  get isRotated() {
    // NOT computable
    const html = document.getElementsByTagName('html')[0]
    if (html.classList.contains('rotate-90')) return 90
    if (html.classList.contains('rotate-270')) return 270
    return 0
  }

  goAccessible = (enable?: boolean) => {
    // toggle accessibility shrink CSS class on <html>
    const html = document.getElementsByTagName('html')[0]
    if (enable === undefined) html.classList.toggle(ACCESSIBLE_CLASS)
    else if (enable) html.classList.add(ACCESSIBLE_CLASS)
    else html.classList.remove(ACCESSIBLE_CLASS)
  }

  rotate = (degree?: 0 | 90 | 270) => {
    // set appropriate rotate CSS class on <html>
    const html = document.getElementsByTagName('html')[0]
    html.classList.remove('rotate-90', 'rotate-270')
    if (degree === 90) html.classList.add('rotate-90')
    else if (degree === 270) html.classList.add('rotate-270')
  }

  search = (term?: string) => {
    if (term !== undefined) this.searchTerm = term
    this.debouncedSearch()
  }

  debouncedSearch = debounce(() => {
    if (this.searchBarFunct) this.searchBarFunct()
  }, CHANGE_DELAY)

  showKeyboard = (doIt: boolean = true) => {
    if (this.isAccessible) {
      this.keyboardYPosition = window.innerHeight - 1850
    } else {
      this.keyboardYPosition = window.innerHeight - 389
    }
    if (!this.useOnScreenKeyboard) return
    else this.keyboardIsVisible = doIt
  }

  keyboardMoved = (e: MouseEvent, data: {y: number}) => {
    this.keyboardYPosition = data.y
  }

  parseQueryString = (search: string) => {
    search
      .substr(1)
      .split('&')
      .forEach(this._parseQueryPart)
  }

  /**
   * Set the global inactivity timeout.
   * Accepts the format "[5m]15[s]"
   */
  setTimeout = (time: string) => {
    if (!time || !time.match) {
      console.warn('Invalid timeout value:', time)
      return
    }
    const match = time.match(TIME_RE)
    if (!match) {
      console.warn('Invalid timeout string:', time)
      return
    }
    // match against [full-match, min+"m", minutes, seconds]
    const [, , str_min, str_sec] = match
    let seconds = +str_sec
    // str_min can be `undefined` if omitted
    if (str_min) seconds += +str_min * 60
    console.debug('Setting timeout to', seconds, 'seconds')

    this.inactivityTimeout = seconds * 1000
  }

  _parseQueryPart = (searchTerm: string) => {
    if (!searchTerm) return

    let [key, value] = searchTerm.split('=')
    key = key.toLowerCase()
    // lowercase value IFF string; could be undefined
    value = typeof value == 'string' ? value.toLowerCase() : value

    switch (key) {
      case 'accessible':
      case 'accessibility':
        if (!isPositiveQueryArg(value)) break
        console.info('Setting accessibility mode')
        this.goAccessible(true)
        break

      case 'rotate':
        if (value !== '270' && value !== '90') break
        console.info(`Rotating screen ${value} degrees`)
        this.rotate(parseInt(value) as 90 | 270)
        break

      case 'screenid':
        const sid = parseInt(value)
        if (isNaN(sid) || sid < 0) break
        console.info(`Setting screenID ${sid}`)
        this.root.screenStore.screenID = String(sid)
        break

      case 'disablerightclick':
        if (!isPositiveQueryArg(value)) break
        console.info('Disabling right click menu')
        this.rightClickDisabled = true
        break

      case 'timeout':
        // accepts "65" or "65s" or "1m5s"
        this.setTimeout(value)
        break

      case 'onscreenkeyboard':
      case 'osk':
        if (!isPositiveQueryArg(value)) break
        console.info('Using On-Screen Keyboard')
        this.useOnScreenKeyboard = true

        break

      case 'disablecursor':
        if (!isPositiveQueryArg(value)) break
        console.log('disabled cursor')
        document.body.style.cursor = 'none'
        break

      default:
        console.warn(`Unrecognized URL parameter "${key}"`)
        break
    }
  }

  get orientation() {
    if (this.platform === 'ios' && window.orientation) {
      switch (window.orientation) {
        case -90:
        case 90:
        case 'landscape':
          return 'landscape'
        default:
          return 'portrait'
      }
    }
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  }

  setOrientation = () => {
    const html = document.getElementsByTagName('html')[0]
    const vp: HTMLMetaElement = document.querySelector("meta[name='viewport']")
    const defaultContent =
      this.platform === 'ios'
        ? 'user-scalable=no, '
        : 'user-scalable=no, initial-scale=1, '

    if (vp && html) {
      if (this.orientation == 'landscape') {
        vp.content = defaultContent + 'height=1080'
        html.classList.add('landscape')
        html.classList.remove('portrait')
      } else {
        vp.content = defaultContent + 'width=1080'
        html.classList.add('portrait')
        html.classList.remove('landscape')
      }
    }
  }
}
