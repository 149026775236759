import * as React from 'react'
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom'

const home = require('images/house2.png')

interface MatchParams {
  dirID?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

class HomeButton extends React.Component<Props> {
  render() {
    return (
      <NavLink to={`/touch/directory/${this.props.match.params.dirID}`}>
        <img className="homebutton" src={String(home)} />
      </NavLink>
    )
  }
}

export default withRouter(HomeButton)
