import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {Link} from 'react-router-dom'

import Directory from 'models/Directory'
import UIStore from 'stores/UIStore'
import withDirectoryWrapper from 'modules/global/DirectoryWrapper'

import './styles/BuildingDirectory.scss'

interface Props {
  directory: Directory
  UIStore?: UIStore
}

@observer
class BuildingDirectory extends React.Component<Props> {
  render() {
    return (
      <div id="building-directory">
        <div className="building-dir-or">- or -</div>
        <div>
          <nav className="directory-nav">
            <li className="nav-item">
              <Link to={`/touch/directory/${this.props.directory.id}/events`}>
                Events
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/touch/directory/${this.props.directory.id}/people`}>
                View Directory
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/touch/directory/${this.props.directory.id}/floormap`}>
                Building Floorplan
              </Link>
            </li>
          </nav>
        </div>
      </div>
    )
  }
}

export default withDirectoryWrapper(BuildingDirectory)
