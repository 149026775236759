import * as React from 'react'
import {computed} from 'mobx'
import {observer, inject} from 'mobx-react'
import {withRouter, RouteComponentProps} from 'react-router'

import Room from 'models/Room'
import TakeMeThere from 'modules/global/TakeMeThere'

import './styles/RoomCard'

interface MatchParams {
  dirID: string
}

interface Props extends RouteComponentProps<MatchParams> {
  room: Room
}

function goto(props: Props) {
  const room = props.room
  props.history.push(
    `/touch/directory/${room.directory_id}/floormap/${room.dir_location_ids[0]}`
  )
}

const RoomCard: React.StatelessComponent<Props> = (props: Props) => {
  const room = props.room
  return (
    <li
      className="card room-card"
      data-id={room.id}
      onClick={goto.bind(this, props)}
    >
      <div className="room-name">
        <span>{room.name}</span>
      </div>
      <div className="vm-bar">
        <TakeMeThere className="vm-button" thing={room} />
      </div>
    </li>
  )
}

export default withRouter(RoomCard)
