const MAIN_RE = /^\/touch\/?$/
const SEARCH_RE = /\/search\/?/
// SEARCH_RE should NOT be prefixed, it should catch both types of searches
const PERSON_RE = /^\/touch\/directory\/\d+\/people\/?/
const EVENT_RE = /^\/touch\/directory\/\d+\/events\/?/
const DIRECTORY_TOP_RE = /^\/touch\/directory\/\d+\/?$/
const DIRECTORY_ANY_RE = /^\/touch\/directory\/\d+?/

export {
  MAIN_RE,
  SEARCH_RE,
  PERSON_RE,
  EVENT_RE,
  DIRECTORY_TOP_RE,
  DIRECTORY_ANY_RE
}
