import {computed} from 'mobx'
import * as Fuse from 'fuse.js'

import {Dir_Event} from 'api/interfaces'
import Event from 'models/Event'
import BaseStore, {FuseResult} from './BaseStore'
import RootStore from './RootStore'

const CHECK_TIMEOUT = 60 * 60 * 1000 // 1 hour

export default class EventStore extends BaseStore<Event> {
  static readonly _searchOptions = Object.freeze(
    Object.assign(
      {
        keys: ['name', 'description', 'roomname']
      },
      BaseStore._searchOptions
    )
  )

  _checkEventTO: NodeJS.Timer | number

  constructor(root: RootStore) {
    super(root)
    this._checkEventTO = setInterval(this.checkEventTimers, CHECK_TIMEOUT)
  }

  @computed
  get fuse() {
    return new Fuse(this.events, EventStore._searchOptions)
  }

  @computed
  get events() {
    return Array.from(this.data.values()).filter(e => e.enabled)
  }

  search = (inputValue: string): FuseResult<Event>[] => {
    return this.fuse.search(inputValue)
  }

  add = (attrs: Dir_Event) => {
    if (!attrs.directory_id) return console.warn('No directory_id on Event')

    const newEvent = new Event(this.root, attrs)

    this.data.set(newEvent.id, newEvent)
    this.root.directoryStore.get(newEvent.directory_id).addEvent(newEvent)
    newEvent.setEnabledTimer()

    return newEvent
  }

  process = (data: Dir_Event[]) => {
    for (const event of data) {
      this.add(event)
    }
  }

  checkEventTimers = () => {
    // check timers for all enabled events
    this.events.forEach(e => e.setEnabledTimer())
  }
}
