import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {observable, computed} from 'mobx'
import {RouteComponentProps, withRouter, Link} from 'react-router-dom'
import * as moment from 'moment'

import {UIStore, DirectoryStore} from 'stores'
import SearchBar from 'modules/global/search/bar'
import HomeButton from 'modules/global/HomeButton'

import './styles/footer'
const MissouriSandTLogo = require('images/Missouri-S&T_PrimaryLogo_Black.png')
const MinerBytesTouchLogo = require('images/20px-margin-minerbytes-touch-no-wheel-no-beta-logo.svg')

interface MatchParams {
  dirID?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  DirectoryStore?: DirectoryStore
}

interface State {}

@inject('DirectoryStore')
@observer
class Footer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  @computed
  get currentDirectory() {
    const directory = this.props.DirectoryStore.get(
      this.props.match.params.dirID
    )

    return (
      directory || {
        name: 'Missouri S&T',
        address: 'Rolla, MO 65409'
      }
    )
  }

  render() {
    return (
      <footer id="global-footer">
        <div className="footer-brand">
          <div className="missourisandt-logo">
            {/* <Link to="/touch"> */}
            <img src={MissouriSandTLogo} />
            {/* </Link> */}
          </div>
          <div className="vertical-line" />
          <div className="building-info">
            <p className="building-name">{this.currentDirectory.name}</p>
            <address>
              {this.currentDirectory.address.split('\n').map((L, i) => (
                <span key={i}>{L}</span>
              ))}
            </address>
          </div>
        </div>
        <div className="powered-by">
          <span>Powered By </span>
          <img className="minerbytes-touch-logo" src={MinerBytesTouchLogo} />
        </div>
      </footer>
    )
  }
}

export default withRouter(Footer)
