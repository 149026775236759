import {observable, ObservableMap, IObservableArray} from 'mobx'
import * as Fuse from 'fuse.js'
import {computed} from 'mobx'

import API from 'api/api'
import {
  Dir_Directory,
  Dir_Stair,
  Dir_Floor,
  DirectoryConfig
} from 'api/interfaces'
import {Event, Floor, Staircase} from 'models'
import {FuseResult, SearchableType} from 'stores/BaseStore'
import {RootStore, FacultyStore, EventStore, RoomStore} from 'stores'

export type FilterCriteria = 'faculty' | 'staff' | 'SPECIAL' | 'other'
export type FilterType =
  | 'filterFaculty'
  | 'filterStaff'
  | 'filterSPECIAL'
  | 'filterOther'

const CONFIG_DEFAULTS: DirectoryConfig = Object.freeze({
  css: '',
  image: null,
  customType: null,
  useSimpleFilter: true
})

export default class Directory implements Dir_Directory {
  id: string
  @observable name: string
  @observable address: string = ''
  @observable description: string = 'No desciption given.'
  readonly dir_person_ids: string[]
  readonly dir_event_ids: string[]
  readonly dir_room_ids: string[]
  readonly dir_location_ids: string[]
  readonly dir_floor_ids: string[]
  @observable config_json: DirectoryConfig

  _root: RootStore
  @observable // map of 'YY/MM' months to event arrays
  monthMap: ObservableMap<string, Event[]> = observable.map()

  @observable staircases: IObservableArray<Staircase> = observable.array()
  @observable floorMap: ObservableMap<string, Floor> = observable.map()

  constructor(rootStore: RootStore, attrs: Dir_Directory) {
    this._root = rootStore
    attrs.address = attrs.address || ''
    attrs.config_json = Object.assign({}, CONFIG_DEFAULTS, attrs.config_json)
    Object.assign(this, attrs)
  }

  @computed
  get dynamicStyles() {
    return this.config_json.css || null
  }

  @computed
  get image() {
    const {image} = this.config_json
    switch (typeof image) {
      case 'number':
        return API.getImgUrl(image)
      case 'string':
        return image as string
    }
    return null
  }

  @computed
  get customType() {
    const {customType} = this.config_json
    if (!customType || !customType.length) return []
    if (typeof customType === 'string') return [customType]
    return customType
  }

  @computed
  get useSimpleFilter() {
    return !!this.config_json.useSimpleFilter
  }

  /**
   * All `type` values contained in the directory.
   */
  @computed
  get allKnownTypes() {
    return (
      this.faculty
        // get types from people
        .map(f => f.type)
        // filter out unique values
        .filter((val, idx, self) => {
          return self.indexOf(val) === idx
        })
    )
  }

  /**
   * The sequence of "main" or "non-Other" filter values.
   *
   */
  @computed
  get mainFilterValues() {
    const values = ['faculty', 'staff']
    if (this.customType) values.push(...this.customType)
    return values
  }

  /**
   * Values to be displayed in the filter bar.
   * Will return the same as `mainFilterValues`, and append "other" only
   *  if there is a non-main filter value.
   */
  @computed
  get allFilterLabels() {
    const values = this.mainFilterValues.slice()
    if (this.allKnownTypes.some(val => this.mainFilterValues.indexOf(val) < 0))
      values.push('other')
    return values as FilterCriteria[]
  }

  /** SECTION: Arrays of {item}s mapped from "{item}IDs" attributes */

  @computed
  get faculty() {
    return this.dir_person_ids.map(id => this._root.facultyStore.get(id))
  }

  @computed
  get rooms() {
    return this.dir_room_ids.map(id => this._root.roomStore.get(id))
  }

  @computed
  get events() {
    return this.dir_event_ids.map(id => this._root.eventStore.get(id))
  }

  @computed
  get locations() {
    return this.dir_location_ids.map(this._root.locationStore.get)
  }

  @computed
  get floors() {
    return [...this.floorMap.values()].sort((a, b) => a.order - b.order)
  }

  /** SECTION: other computed properties */

  @computed
  get screens() {
    return this._root.screenStore.screens.filter(
      scrn => scrn.directory_id === this.id
    )
  }

  @computed
  get facultyFuse() {
    return new Fuse(this.faculty, FacultyStore._searchOptions)
  }

  @computed
  get roomFuse() {
    return new Fuse(this.rooms, RoomStore._searchOptions)
  }

  @computed
  get eventFuse() {
    return new Fuse(this.events, EventStore._searchOptions)
  }

  search = (inputValue: string) => {
    const results: FuseResult<SearchableType>[] = []
    ;[this.facultyFuse, this.roomFuse, this.eventFuse].forEach(aFuse =>
      results.push(...aFuse.search(inputValue))
    )
    return results
  }

  facultySearch = (inputValue: string) => this.facultyFuse.search(inputValue)
  eventSearch = (inputValue: string) => this.eventFuse.search(inputValue)

  addEvent = (event: Event) => {
    const yearmonth = event.start.format('YY/MM')

    if (this.dir_event_ids.indexOf(event.id) === -1)
      this.dir_event_ids.push(event.id)

    if (!this.monthMap.has(yearmonth)) {
      this.monthMap.set(yearmonth, observable.array([event]))
    } else {
      this.monthMap.get(yearmonth).push(event)
    }
  }

  addStairsAndFloors = (dirstairs: Dir_Stair[], dirfloors: Dir_Floor[]) => {
    for (const dfloor of dirfloors) {
      this.floorMap.set(dfloor.id, new Floor(this._root, dfloor))
    }
    this.staircases.replace(Staircase.stairsToStaircases(this, dirstairs))
  }
}
