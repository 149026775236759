import {observable, action, computed} from 'mobx'

import {Dir_Directory, Dir_Stair, Dir_Floor} from 'api/interfaces'
import Directory from 'models/Directory'
import Faculty from 'models/Faculty'
import BaseStore, {FuseResult} from './BaseStore'
import api from 'api/api'

export default class DirectoryStore extends BaseStore<Directory> {
  @observable currentDirectory: Directory = null

  @computed
  get directories() {
    return Array.from(this.data.values())
  }

  search = (): FuseResult<Directory>[] => []

  add = (attrs: Dir_Directory) => {
    const newDirectory = new Directory(this.root, attrs)

    this.data.set(newDirectory.id, newDirectory)
    //newDirectory.faculty.forEach(this.root.facultyStore.add)
    //newDirectory.events.forEach(this.root.eventStore.add)
    //newDirectory.rooms.forEach(this.root.roomStore.add)

    return newDirectory
  }

  /**
   * Get a directory, either from the store or server.
   * This is THE method for loading a single directory, unpacking and attaching
   * all associated data (except media).
   */
  fetch = async (id: string, hard: boolean = false) => {
    const model = this.get(id)
    if (!hard && model) return model

    const data = await api.get(id)

    this.add(data.directory)
    this.root.screenStore.process(data.screens)
    this.root.facultyStore.process(data.persons)
    this.root.eventStore.process(data.events)
    this.root.roomStore.process(data.rooms)
    this.root.locationStore.process(data)
    const dir = this.get(data.directory.id)
    dir.addStairsAndFloors(data.stairs, data.floors)
    return dir
  }

  /**
   * Get all directories from the server.
   * This is THE method for loading all directories (even if they're already
   * loaded), unpacking and attaching all associated data (except media).
   */
  fetchAll = async () => {
    const data = await api.getAll()

    const dirs = data.directories.map(d => this.add(d))
    this.root.screenStore.process(data.screens)
    this.root.facultyStore.process(data.persons)
    this.root.eventStore.process(data.events)
    this.root.roomStore.process(data.rooms)
    this.root.locationStore.process(data)

    // Bin and add all the stairs/floors to associated directory
    const bins = this.binStairsAndFloors(data.stairs, data.floors)
    for (const [dir_id, [stairs, floors]] of bins)
      this.get(dir_id).addStairsAndFloors(stairs, floors)

    return dirs
  }

  @action
  loadDirectory = async (id: string) => {
    const directory = await this.fetch(id)

    if (!this.currentDirectory || this.currentDirectory.id !== id) {
      this.currentDirectory = directory
    }
    const {currentScreen, screens} = this.root.screenStore
    if (!currentScreen || currentScreen.directory_id != id) {
      this.root.screenStore.screenID = null
      for (const scrn of screens)
        if (scrn.directory_id == id) {
          this.root.screenStore.screenID = scrn.id
          break
        }
    }

    return directory
  }

  addToDirectory = (faculty: Faculty, directory: Directory) => {
    faculty.directory_id = directory.id
    directory.faculty.push(faculty)
  }

  /**
   * Create a map of directory IDs to 2-tuples of stair and floor arrays.
   *
   */
  binStairsAndFloors = (stairs: Dir_Stair[], floors: Dir_Floor[]) => {
    const map = new Map<string, [Dir_Stair[], Dir_Floor[]]>()
    stairs.forEach(stair => {
      let V = map.get(stair.directory_id)
      if (V) V[0].push(stair)
      else map.set(stair.directory_id, [[stair], []])
    })
    floors.forEach(floor => {
      let V = map.get(floor.directory_id)
      if (V) V[1].push(floor)
      else map.set(floor.directory_id, [[], [floor]])
    })
    return map
  }
}
