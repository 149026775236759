import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {computed} from 'mobx'
import {RouteComponentProps, withRouter} from 'react-router-dom'

import {DirectoryStore} from 'stores'
import SearchBar from 'modules/global/search/bar'
import HomeButton from 'modules/global/HomeButton'
import HeaderWelcome from 'modules/global/HeaderWelcome'
import {
  SEARCH_RE,
  PERSON_RE,
  EVENT_RE,
  DIRECTORY_ANY_RE,
  MAIN_RE,
  DIRECTORY_TOP_RE
} from 'modules/global/re'

import './styles/header.scss'

interface MatchParams {
  dirID?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  directoryName?: string
  DirectoryStore?: DirectoryStore
}

interface State {}

@inject('DirectoryStore')
@observer
class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  @computed
  get hasHomeButton() {
    return (
      DIRECTORY_ANY_RE.test(this.props.location.pathname) &&
      !DIRECTORY_TOP_RE.test(this.props.location.pathname)
    )
  }

  @computed
  get showWelcome(): boolean {
    const {pathname} = this.props.location
    return MAIN_RE.test(pathname) || DIRECTORY_TOP_RE.test(pathname)
  }

  @computed
  get title() {
    const p = this.props.location.pathname
    if (SEARCH_RE.test(p)) return 'Directory Search'
    if (PERSON_RE.test(p)) return 'People Directory'
    if (EVENT_RE.test(p)) return 'Event Directory'
    return 'Directory'
  }

  render() {
    return (
      <header
        id="global-header"
        className={this.showWelcome ? 'with-welcome' : null}
      >
        {this.hasHomeButton && <HomeButton />}
        <h1>{this.title}</h1>
        <HeaderWelcome
          show={this.showWelcome}
          DirectoryStore={this.props.DirectoryStore}
        />
        <SearchBar />
      </header>
    )
  }
}

export default withRouter(Header)
