import * as React from 'react'
import UIStore from 'stores/UIStore'
import {inject, observer} from 'mobx-react'
import Keyboard from 'react-simple-keyboard'

import './styles/TouchscreenKeyboard.scss'
import KeyboardReact from 'react-simple-keyboard'
import Draggable from 'react-draggable'

interface Props {
  UIStore: UIStore
}

interface State {
  currentLayout: 'default' | 'shift' | 'numbers'
}

@inject('UIStore')
@observer
export default class TouchscreenKeyboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentLayout: 'default'
    }
  }

  private keyboard: KeyboardReact

  onKeyPress = (button: any) => {
    if (button === '{clear}') {
      this.keyboard.clearInput('default')
      this.props.UIStore.search('')
      this.keyboard.keyboard.caretPosition = 0
    }
    if (button === '{shift}') this.handleShift()
    if (button === '{numbers}' || button === '{abc}') this.handleNumbers()
  }

  handleShift = () => {
    const shiftToggle =
      this.state.currentLayout === 'default' ? 'shift' : 'default'

    this.setState({
      currentLayout: shiftToggle
    })
  }

  handleNumbers = () => {
    const numbersToggle =
      this.state.currentLayout !== 'numbers' ? 'numbers' : 'default'

    this.setState({
      currentLayout: numbersToggle
    })
  }

  setRef = (r: any) => {
    if (r != null) {
      this.keyboard = r
      this.keyboard.setInput(this.props.UIStore.searchTerm, 'default')
    }
  }

  render() {
    return this.props.UIStore.keyboardIsVisible ? (
      <Draggable
        axis="y"
        handle=".draggable-area"
        bounds="#main-wrapper"
        onStop={this.props.UIStore.keyboardMoved}
        position={{x: 0, y: this.props.UIStore.keyboardYPosition}}
      >
        <div id="draggable-keyboard">
          <div className="draggable-area">
            <div id="drag-target">Drag to relocate</div>
            <hr />
          </div>
          <Keyboard
            ref={this.setRef}
            layoutName={this.state.currentLayout}
            onChange={this.props.UIStore.search}
            preventMouseDownDefault={true}
            onKeyPress={this.onKeyPress}
            autoUseTouchEvents
            mergeDisplay={true}
            theme={'mb-keyboard'}
            inputName={'default'}
            layout={{
              default: [
                'q w e r t y u i o p',
                'a s d f g h j k l',
                '{shift} z x c v b n m {backspace}',
                '{numbers} {space} {clear}'
              ],
              shift: [
                'Q W E R T Y U I O P',
                'A S D F G H J K L',
                '{shift} Z X C V B N M {backspace}',
                '{numbers} {space} {clear}'
              ],
              numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}']
            }}
            display={{
              '{numbers}': '123',
              '{clear}': 'clear',
              '{backspace}': '⌫',
              '{shift}': '⇧',
              '{abc}': 'ABC'
            }}
          />
        </div>
      </Draggable>
    ) : null
  }
}
