import axios from 'axios'

import {IndexResponseData, DirectoryResponseData} from './interfaces'

const BASE = 'https://minerbytes-d2.managed.mst.edu/api'

const http = axios.create({
  baseURL: BASE
})

export default {
  getAll: async function(): Promise<IndexResponseData> {
    return (await http.get(`/touch`)).data
  },
  get: async function(id: string): Promise<DirectoryResponseData> {
    return (await http.get(`/touch/${id}`)).data
  },
  getImgUrl: function(imgID: string | number): string {
    return `${BASE}/touch/images/${imgID}`
  }
}
