import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {withRouter, RouteComponentProps} from 'react-router'

import BaseStore, {
  SearchableType,
  FuseResult,
  SearchFunction
} from 'stores/BaseStore'
import EventStore from 'stores/EventStore'
import RoomStore from 'stores/RoomStore'
import FacultyStore from 'stores/FacultyStore'
import UIStore from 'stores/UIStore'
import DirectoryStore from 'stores/DirectoryStore'
import {SEARCH_RE, PERSON_RE, EVENT_RE} from 'modules/global/re'

import './styles/search.scss'

type SearchMode =
  | 'filter-Faculty'
  | 'filter-Event'
  | 'search-directory'
  | 'search-all'

interface MatchParams {
  dirID?: string
  facultyID?: string
  eventID?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  EventStore?: EventStore
  RoomStore?: RoomStore
  FacultyStore?: FacultyStore
  UIStore?: UIStore
  DirectoryStore?: DirectoryStore
}

@inject('DirectoryStore', 'EventStore', 'RoomStore', 'FacultyStore', 'UIStore')
@observer
class SearchBar extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.props.UIStore.searchBarFunct = this.search
  }

  componentWillUnmount() {
    this.props.UIStore.searchBarFunct = null
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.location.pathname != this.props.location.pathname &&
      !SEARCH_RE.test(this.props.location.pathname)
    ) {
      this.props.UIStore.searchTerm = ''
    }
  }

  @computed
  get currentDirectory() {
    return this.props.DirectoryStore.currentDirectory
  }

  @computed
  get searchFunction(): SearchFunction<SearchableType> {
    // returns a callable that takes a string and returns results
    switch (this.searchMode) {
      case 'filter-Faculty':
        return this.currentDirectory.facultySearch

      case 'filter-Event':
        return this.currentDirectory.eventSearch

      case 'search-directory':
        return this.currentDirectory.search
    }
    return this.allStoreSearch
  }

  @computed
  get searchMode(): SearchMode {
    if (PERSON_RE.test(this.props.location.pathname)) return 'filter-Faculty'
    if (EVENT_RE.test(this.props.location.pathname)) return 'filter-Event'
    if (this.props.match.path.includes(':dirID')) return 'search-directory'
    return 'search-all'
  }

  @computed
  get shouldRedirect() {
    if (this.searchMode.startsWith('filter')) return false
    if (SEARCH_RE.test(this.props.match.path)) return false
    if (this.searchMode == 'search-directory')
      return `/touch/directory/${this.props.match.params.dirID}/search`
    return '/touch/search'
  }

  allStoreSearch = (inputValue: string) => {
    // alternative to store search that joins straight store searches
    const results: FuseResult<SearchableType>[] = []
    ;[
      this.props.EventStore,
      this.props.RoomStore,
      this.props.FacultyStore
    ].forEach(store => results.push(...store.search(inputValue)))
    return results
  }

  onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const {searchTerm, searchResults, search} = this.props.UIStore
    if (searchTerm && !searchResults.length) search()
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (this.props.UIStore.searchTerm !== inputValue) {
      this.props.UIStore.search(inputValue)
    }
  }

  search = () => {
    // function for initiating search processing
    // see this.searchFunction for the actual search mechanisms
    const inputValue = this.props.UIStore.searchTerm

    if (!inputValue) {
      this.props.UIStore.searchResults.clear()
      return
    }

    this.props.UIStore.searchResults.replace(this.searchFunction(inputValue))

    if (this.shouldRedirect) this.props.history.push(this.shouldRedirect)
  }

  render() {
    return (
      <div className="search-bar">
        <input
          id="the-search-bar"
          type="search"
          value={this.props.UIStore.searchTerm}
          onChange={this.onChange}
          onClick={this.onClick}
          onFocus={this.props.UIStore.showKeyboard.bind({}, true)}
          onBlur={this.props.UIStore.showKeyboard.bind({}, false)}
          results={5}
          maxLength={50}
          placeholder="Tap to search..."
        />
      </div>
    )
  }
}

export default withRouter(SearchBar)
