import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {Link} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'

import {DirectoryStore, ScreenStore, UIStore} from 'stores'
import {MAIN_RE} from 'modules/global/re'

import './styles/MainDirectory.scss'
import 'modules/global/styles/Button.scss'

interface Props extends RouteComponentProps<any> {
  DirectoryStore: DirectoryStore
  UIStore: UIStore
  ScreenStore: ScreenStore
}

interface State {}

@inject('DirectoryStore', 'UIStore', 'ScreenStore')
@observer
export default class MainDirectory extends React.Component<Props, State> {
  componentDidMount() {
    this.props.DirectoryStore.currentDirectory = null

    // Potentially redirect based on pre-render conditons
    if (!MAIN_RE.test(this.props.location.pathname)) {
      console.warn('MainDirectory received bad URL:', this.props.match.url)
      this.props.history.push('/touch')
      // but don't return yet, we may need to update DirectoryStore
    }

    if (this.props.DirectoryStore.directories.length < 2) {
      console.info('Querying directories')
      this.loadDirectories(this.props)
    }
  }

  /**
   * Fetch directories and potentially redirect the application.
   * If we landed on this page with `?screenID=N` and that screen exists,
   *    redirect to that screen's directory.
   * If only one directory exists, redirect to it.
   */
  loadDirectories = (props: Props) => {
    props.UIStore.globalLoading++
    props.DirectoryStore.fetchAll().then(directories => {
      const screen = props.ScreenStore.currentScreen
      const searchString = props.location.search.toLowerCase()
      if (searchString.includes('screenid') && screen && screen.directory_id) {
        console.info("Received screen's directory ID, redirecting")
        props.history.push(`/touch/directory/${screen.directory_id}`)
      } else if (directories && directories.length === 1) {
        console.info('Received exactly 1 directory, redirecting')
        props.history.push(`/touch/directory/${directories[0].id}`)
      }
      props.UIStore.globalLoading--
    })
  }

  render() {
    const directories = this.props.DirectoryStore.directories

    return (
      <div>
        <ul className="building-card-list">
          {directories.map((directory, i) => (
            <Link
              style={{textDecoration: 'none'}}
              key={i}
              to={`/touch/directory/${directory.id}`}
            >
              <li className="building-card" key={i}>
                <img src={directory.image} width="200px" height="200px" />
                <p className="card-title">{directory.name}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    )
  }
}
