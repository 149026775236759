import * as React from 'react'
import {observer} from 'mobx-react'

import PathFinder from './PathFinder'
import FloorPlan from '../FloorPlan'
//import floorplans from './floorplans'
import {LocationStore} from 'stores'
import {Floor, Location, Staircase} from 'models'

interface Props {
  LocationStore: LocationStore
  destination: Location
  from: Location
  floorplans: FloorPlan[]
  selectedFloor: Floor
}

@observer
export default class MultiFloorPathFinderInner extends React.Component<Props> {
  destinationNotOnFloor = (): boolean => {
    if (
      this.props.destination &&
      this.props.from &&
      this.props.destination.floor_id !== this.props.from.floor_id
    ) {
      return true
    }
    return false
  }

  renderSelectedFloor = () => (
    <PathFinder
      LocationStore={this.props.LocationStore}
      floorplan={this.props.floorplans[this.props.selectedFloor.order]}
      destination={null}
      from={null}
    />
  )

  renderSingleFloorPathFinding = () => {
    const {from, floorplans} = this.props
    let target_floor: FloorPlan

    for (const fp of floorplans) {
      if (fp.floor.id === from.floor_id) {
        target_floor = fp
      }
    }

    return (
      <PathFinder
        LocationStore={this.props.LocationStore}
        floorplan={target_floor}
        destination={this.props.destination}
        from={this.props.from}
        isDestination={true}
      />
    )
  }

  renderMultiFloorPathFinding = () => {
    const {from, destination, floorplans} = this.props
    let start_floor: FloorPlan
    let end_floor: FloorPlan
    let stair: Staircase

    for (const fp of floorplans) {
      if (fp.floor.id === from.floor_id) start_floor = fp
      if (fp.floor.id === destination.floor_id) end_floor = fp
      if (start_floor && end_floor) break
    }
    if (!start_floor || !end_floor) {
      console.error('Missing start/end floor', start_floor, end_floor)
      return null
    }

    // All floors in the floorplan have the same destination/stairs/from so doesn't matter what index we access.
    for (const staircase of start_floor.stairs) {
      if (staircase.dir_floor_ids.indexOf(destination.floor_id) >= 0) {
        stair = staircase
        break
      }
    }
    if (!stair) {
      console.error('No floor-in-common stairs', start_floor, end_floor)
      return null
    }

    const start_args = {
      destination: this.props.LocationStore.get(
        stair.floorToLocationMap.get(start_floor.floor.id)
      ),
      from: this.props.from
    }

    const destination_args = {
      destination: destination,
      // Find stairs on the same floor as the destination
      from: this.props.LocationStore.get(
        stair.floorToLocationMap.get(end_floor.floor.id)
      )
    }

    return (
      <div className={'pathfinding-container-multiple'}>
        <PathFinder
          LocationStore={this.props.LocationStore}
          floorplan={start_floor}
          {...start_args}
        />
        <PathFinder
          LocationStore={this.props.LocationStore}
          floorplan={end_floor}
          {...destination_args}
          isDestination={true}
        />
      </div>
    )
  }

  render() {
    if (
      this.props.selectedFloor &&
      (!(this.props.from && this.props.destination) ||
        this.props.selectedFloor.id !== this.props.from.floor_id)
    ) {
      return this.renderSelectedFloor()
    }

    if (this.destinationNotOnFloor()) {
      return this.renderMultiFloorPathFinding()
    }
    return this.renderSingleFloorPathFinding()
  }
}
