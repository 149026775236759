import * as React from 'react'
import {UIStore} from 'stores'
import {inject, observer} from 'mobx-react'
import 'modules/global/styles/globalLoadingSpinner.scss'

const MBLogo = require('images/mb-logo.svg')

interface Props {
  UIStore: UIStore
}

interface State {}

@observer
export default class GlobalLoadingSpinner extends React.Component<
  Props,
  State
> {
  render() {
    if (this.props.UIStore.globalLoading <= 0) {
      return null
    }
    return (
      <div id="global-loading-spinner">
        <img src={MBLogo} />
        <p>Fetching new content...</p>
      </div>
    )
  }
}
