import * as React from 'react'
import {observer} from 'mobx-react'
import {computed} from 'mobx'

import LocationStore from 'stores/LocationStore'
import Location from 'models/Location'
import FloorPlan from '../FloorPlan'
import '../styles/FloorMap.scss'

const CANVAS_WIDTH = 766
const CANVAS_HEIGHT = 633

//havener = [1916, 1582], scale = 0.4

function scaleToFit({width, height}: {width: number; height: number}) {
  const ratio = Math.min(CANVAS_WIDTH / width, CANVAS_HEIGHT / height)
  return ratio
}

interface Props {
  LocationStore: LocationStore
  floorplan: FloorPlan
  destination: Location
  from: Location
  isDestination?: boolean
}

interface State {
  initialized: boolean
  mapImage: HTMLImageElement
  scale: number
}

@observer
export default class PathFinder extends React.Component<Props, State> {
  refs: {
    canvas: HTMLCanvasElement
  }

  state: State = {
    initialized: false,
    mapImage: null,
    scale: 1
  }

  componentDidMount() {
    this.resetCanvas().then(this.findPath)
  }

  componentDidUpdate(prevProps: Props, prevState: Readonly<State>) {
    // react to changed location ONLY AFTER DidMount() reset initializes it.
    if (prevState.initialized) {
      // Only do a 'hardReset' if the floorplan changes
      if (
        this.props.floorplan &&
        prevProps.floorplan &&
        this.props.floorplan.floor != prevProps.floorplan.floor
      )
        this.resetCanvas(true).then(this.findPath)
      if (
        this.props.from != prevProps.from ||
        this.props.destination != prevProps.destination
      )
        this.resetCanvas().then(this.findPath)
    }
  }

  @computed
  get trueDestination(): Location | null {
    // return (true as any) as Location
    const {destination, isDestination} = this.props
    if (this.props.floorplan) {
      const {floor} = this.props.floorplan
      if (
        !isDestination ||
        !destination ||
        !floor ||
        destination.floor_id !== floor.id
      ) {
        return null
      } else {
        return destination
      }
    }
  }

  @computed
  get youAreHere(): Location | null {
    const {screenLocation} = this.props.LocationStore
    if (this.props.floorplan) {
      const {floor} = this.props.floorplan
      if (!screenLocation || !floor || screenLocation.floor_id !== floor.id) {
        return null
      }
    }
    return screenLocation
  }

  resetCanvas = (hardReset?: boolean): Promise<undefined> => {
    const ctx = this.getContext()

    if (hardReset) {
      console.info('HardReset')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
      this.setState({initialized: false, mapImage: null, scale: 1})
    }

    return new Promise(resolve => {
      if (!this.state.mapImage || hardReset) {
        // not yet initialized
        const mapImage = new Image()
        this.setState({mapImage})

        if (this.props.floorplan) {
          mapImage.src = this.props.floorplan.floor.image
          mapImage.onload = () => {
            const scale = scaleToFit(mapImage)

            ctx.setTransform(1, 0, 0, 1, 0, 0)
            ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
            ctx.scale(scale, scale)

            ctx.drawImage(mapImage, 0, 0)
            this.drawYouAreHere(ctx)
            this.drawLegend(ctx, scale)
            this.setState({mapImage, scale, initialized: true}, resolve)
          }
        }
      } else if (this.state.initialized) {
        // has been initialized, just redraw
        const {mapImage, scale} = this.state

        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
        ctx.scale(scale, scale)

        ctx.drawImage(mapImage, 0, 0)
        this.drawYouAreHere(ctx)
        this.drawLegend(ctx, scale)
        resolve()
      }
    })
  }

  getContext = (): CanvasRenderingContext2D => {
    return this.refs.canvas.getContext('2d')
  }

  drawAllLocations = () => {
    this.props.floorplan.destinations.forEach(this.drawLocation)
  }

  drawYouAreHere = (ctx: CanvasRenderingContext2D) => {
    const {youAreHere} = this
    if (youAreHere) {
      this._drawStar(ctx, youAreHere, 20)
    }
  }

  drawLegend = (ctx: CanvasRenderingContext2D, scale: number) => {
    const {youAreHere, trueDestination} = this
    if (!youAreHere && !trueDestination) return
    console.debug('scale:', scale)
    const realWidth = CANVAS_WIDTH / scale
    const realHeight = CANVAS_HEIGHT / scale
    const lineHeight = 45
    const legendWidth = 230 + 40

    const tDheight = youAreHere ? realHeight - lineHeight : realHeight
    const yAHheight = realHeight

    /// backup styles
    const {font, fillStyle, strokeStyle, lineWidth, textAlign} = ctx
    ctx.fillStyle = 'gray'
    if (youAreHere && trueDestination) {
      ctx.fillRect(realWidth, realHeight, -legendWidth, -2 * lineHeight)
    } else {
      ctx.fillRect(realWidth, realHeight, -legendWidth, -lineHeight)
    }
    ctx.font = '30px Times New Roman'
    ctx.textAlign = 'right'
    ctx.fillStyle = 'white'
    ctx.textBaseline = 'bottom'
    if (youAreHere) {
      ctx.fillText('You Are Here', realWidth - 10, yAHheight - 5)
      this._drawStar(
        ctx,
        {
          x: realWidth - legendWidth + 25,
          y: yAHheight - lineHeight / 2
        },
        12
      )
    }
    if (trueDestination) {
      ctx.fillText('Destination', realWidth - 10, tDheight - 5)
      this._drawX(
        ctx,
        {x: realWidth - legendWidth + 25, y: tDheight - lineHeight / 2},
        12
      )
    }

    Object.assign(ctx, {font, fillStyle, strokeStyle, lineWidth, textAlign})
  }

  drawLocation = (location: Location) => {
    const ctx = this.getContext()
    ctx.beginPath()
    this._drawCircle(ctx, location, 8)
    ctx.fill()
  }

  connectLocation = (a: Location, b: Location) => {
    const ctx = this.getContext()
    ctx.moveTo(+a.x, +a.y)
    ctx.lineTo(+b.x, +b.y)
    ctx.stroke()
  }

  findPath = () => {
    if (!this.props.destination || !this.props.from) return

    const start = this.props.from
    const end = this.props.destination
    const frontier = [start]
    const came_from: Map<Location, Location> = new Map()

    while (frontier.length) {
      const current = frontier.shift()
      const currentLocation = this.props.LocationStore.get(current.id)
      if (current === end) break
      currentLocation.neighbors.forEach(neighbor => {
        if (!came_from.get(neighbor)) {
          frontier.push(neighbor)
          came_from.set(neighbor, current)
        }
      })
    }
    let current = end
    const path: Location[] = []
    while (current && start != current) {
      path.push(this.props.LocationStore.get(current.id))
      current = came_from.get(current)
    }
    path.push(this.props.LocationStore.get(start.id))

    if (current) {
      this.drawPath(path.reverse())
    } else {
      console.groupCollapsed()
      console.log('============NO PATH ERROR============')
      console.log('FROM:')
      console.dir(this.props.from)
      console.log('DESTINATION:')
      console.dir(this.props.destination)
      console.log('============END OF DEBUG=============')
      console.groupEnd()
    }
  }

  _drawCircle = (
    ctx: CanvasRenderingContext2D,
    location: Location,
    radius: number
  ) => {
    ctx.arc(+location.x, +location.y, radius, 0, Math.PI * 2)
  }

  _drawStar = (
    ctx: CanvasRenderingContext2D,
    location: {x: number | string; y: number | string},
    radius: number
  ) => {
    const points = 5
    const rotationFactor = Math.PI / points
    const iterations = points * 2
    const {x, y} = location
    ctx.beginPath()
    for (let i = iterations; i > 0; i--) {
      const R = (radius * ((i % 2) + 1)) / 2
      const omega = rotationFactor * i
      ctx.lineTo(R * Math.sin(omega) + +x, R * Math.cos(omega) + +y)
    }
    ctx.closePath()
    /// backup styles
    const {fillStyle, strokeStyle, lineWidth} = ctx
    ctx.fillStyle = '#007a33'
    ctx.strokeStyle = 'white'
    ctx.lineWidth = 4
    ctx.stroke()
    ctx.fill()
    Object.assign(ctx, {fillStyle, strokeStyle, lineWidth})
  }

  _drawX = (
    ctx: CanvasRenderingContext2D,
    location: {x: number | string; y: number | string},
    radius: number
  ) => {
    const {strokeStyle, lineWidth, lineCap} = ctx
    const pairs: [number, string][] = [[12, 'white'], [8, '#007a33']]
    for (const [desiredWidth, color] of pairs) {
      ctx.beginPath()
      ctx.moveTo(+location.x - radius, +location.y - radius)
      ctx.lineTo(+location.x + radius, +location.y + radius)
      ctx.moveTo(+location.x - radius, +location.y + radius)
      ctx.lineTo(+location.x + radius, +location.y - radius)
      ctx.strokeStyle = color
      ctx.lineWidth = desiredWidth
      ctx.lineCap = 'square'
      ctx.closePath()
      ctx.stroke()
    }
    Object.assign(ctx, {strokeStyle, lineWidth, lineCap})
  }

  drawPath = async (path: Location[]) => {
    await this.resetCanvas()

    const ctx = this.getContext()
    ctx.lineWidth = 10
    ctx.lineCap = 'round'
    ctx.setLineDash([15, 22])
    ctx.strokeStyle = 'red'

    ctx.beginPath()
    path.slice(0, path.length - 1).forEach((location, i) => {
      this.connectLocation(location, path[i + 1])
    })
    ctx.closePath()

    ctx.beginPath()
    ctx.setLineDash([])
    ctx.fillStyle = 'white'
    if (!this.trueDestination) {
      const lastLoc = path[path.length - 1]
      this._drawCircle(ctx, lastLoc, 12)
    }
    ctx.stroke()
    ctx.fill()
    ctx.closePath()

    this.drawYouAreHere(ctx)
    if (this.trueDestination) {
      this._drawX(ctx, this.trueDestination, 15)
    }
  }

  prettyDescription = () => {
    if (!this.props.destination || !this.props.from)
      return 'Select a destination to navigate'

    if (!this.props.LocationStore.data.has(this.props.destination.id))
      return 'Location does not exist in touch'

    if (!this.props.LocationStore.data.has(this.props.from.id))
      return 'Location does not exist in touch'

    const from = this.props.from
    const dest = this.props.destination
    const fromName = (from.room ? from.room : from).name
    const destName = (dest.room ? dest.room : dest).name

    return `Navigating from ${fromName} to ${destName}`
  }

  render() {
    return (
      <div className="pathfinding-container">
        <p>{this.prettyDescription()}</p>
        <canvas ref="canvas" width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
      </div>
    )
  }
}
