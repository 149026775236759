import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {computed} from 'mobx'
import {RouteComponentProps} from 'react-router'

import Directory from 'models/Directory'
import Event from 'models/Event'
import EventStore from 'stores/EventStore'
import MonthSection from './MonthSection'
import EventModal from './EventModal'
import withDirectoryWrapper from 'modules/global/DirectoryWrapper'
import './styles/EventCard'
import {UIStore} from 'stores'

interface MatchParams {
  dirID: string
  eventID?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  directory: Directory
  EventStore?: EventStore
  UIStore?: UIStore
}

interface State {}

@inject('EventStore', 'UIStore')
@observer
class EventDirectory extends React.Component<Props, State> {
  @computed
  get selectedEvent() {
    const id = this.props.match.params.eventID
    return this.props.EventStore.get(id) || null
  }

  /**
   * Returns an array of year/month pairs of form "YY/MM"
   */
  @computed
  get yearmonths() {
    return Array.from(this.props.directory.monthMap.keys()).sort()
  }

  goto = (eventID?: string) => {
    this.props.history.push(
      this.props.match.path
        .replace(':dirID', this.props.match.params.dirID)
        .replace(':eventID?', eventID || '')
    )
  }

  openModal = (event?: Event) => {
    this.goto(event && event.id ? event.id : '')
  }

  closeModal = () => {
    this.goto()
  }

  render() {
    return (
      <div className="card-dir-wrap event-dir-wrap">
        {/*
        <ButtonToolbar className="card-dir-controls event-controls">
          <ButtonGroup bsSize="large">
            <Button>A</Button>
            <Button>Future</Button>
            <Button>Button</Button>
          </ButtonGroup>
        </ButtonToolbar>
        */}
        <div className="card-directory event-directory">
          {(this.props.UIStore.searchResultIDs.length ||
            !this.props.UIStore.searchTerm) &&
          this.props.directory.monthMap.size ? (
            this.yearmonths.map(yearmonth => (
              <MonthSection
                key={yearmonth}
                directory={this.props.directory}
                yearmonth={yearmonth}
                openModal={this.openModal}
              />
            ))
          ) : (
            <div className="search-result-placeholder">
              <p>No events found</p>
              <p>Enter new search or press home button</p>
            </div>
          )}
        </div>
        <EventModal event={this.selectedEvent} onClose={this.closeModal} />
      </div>
    )
  }
}

export default withDirectoryWrapper(EventDirectory)
