import * as React from 'react'

import './styles/CampusMap.scss'

const CampusMap = () => {
  return (
    <div id="campus-map">
      <iframe
        className="map"
        src="https://www.google.com/maps/d/embed?mid=1Kn5szh-ZXKsEPOaiqHrITQ-yL3Y"
      />
    </div>
  )
}

export default CampusMap
