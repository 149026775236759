import * as React from 'react'
import {Link, RouteComponentProps} from 'react-router-dom'

interface Props extends RouteComponentProps<{}> {}

export default class CatchPage extends React.Component<Props> {
  render() {
    return (
      <div>
        <h3>
          404 Not Found: <code>{this.props.location.pathname}</code>
        </h3>
        <p>
          <Link to="/touch">Return to Home</Link>
        </p>
      </div>
    )
  }
}
