import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'mobx-react'

import RootStore from 'stores/index'
import Routes from 'routes'

ReactDOM.render(
  <Provider
    DirectoryStore={RootStore.directoryStore}
    FacultyStore={RootStore.facultyStore}
    UIStore={RootStore.uiStore}
    RoomStore={RootStore.roomStore}
    EventStore={RootStore.eventStore}
    LocationStore={RootStore.locationStore}
    ScreenStore={RootStore.screenStore}
  >
    <Routes />
  </Provider>,
  document.getElementById('app')
)
