import {observable, computed} from 'mobx'
import * as moment from 'moment'

import {Dir_Event} from 'api/interfaces'
import RootStore from 'stores/RootStore'

const MAX_TIMEOUT_HOURS = 24

export default class Event {
  id: string
  @observable name: string
  @observable start: moment.Moment
  @observable end: moment.Moment
  @observable description: string
  @observable directory_id: string
  @observable room_id: string | null
  @observable dir_room_ids: string[] = observable.array()
  @observable dir_person_ids: string[] = observable.array()
  @observable enabled: boolean
  _root: RootStore
  _enabledTimer: NodeJS.Timer | number

  constructor(rootStore: RootStore, attrs: Dir_Event) {
    this._root = rootStore
    const overrides = {
      start: moment(attrs.start),
      end: moment(attrs.end),
      enabled: moment().isBefore(attrs.end)
    }

    Object.assign(this, attrs, overrides)
  }

  @computed
  get roomname() {
    if (this.dir_room_ids.length > 0 && !this.room_id) {
      // Default to the first room id if there is no set id.
      this.room_id = this.dir_room_ids[0]
    }

    const r = this._root.roomStore.get(this.room_id)
    return r ? r.name : ''
  }

  @computed
  get persons() {
    return this.dir_person_ids.map(pid => this._root.facultyStore.get(pid))
  }

  @computed
  get multiday() {
    return !this.start.isSame(this.end, 'day')
  }

  sortCmp = (rhs: Event) => {
    if (this.start.isBefore(rhs.start)) return -1
    if (this.start.isAfter(rhs.start)) return 1
    // starts are same
    if (this.end.isBefore(rhs.end)) return -1
    if (this.end.isAfter(rhs.end)) return 1
    return 0
  }

  setEnabledTimer = () => {
    if (!this.enabled || this._enabledTimer > 0) {
      // no need to set a timer
      return false
    } else if (moment().isAfter(this.end)) {
      // already passed, set enabled to false
      this.enabled = false
      return false
    } else if (this.end.diff([], 'hours') > MAX_TIMEOUT_HOURS) {
      // too far in the future, don't set a timer
      return false
    }
    // it's near enough to set a timer!
    const timeTil = this.end.diff([])
    this._enabledTimer = setTimeout(() => (this.enabled = false), timeTil)
    return true
  }
}
