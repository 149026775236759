import {observable, computed} from 'mobx'

import API from 'api/api'
import {Dir_Room} from 'api/interfaces'
import RootStore from 'stores/RootStore'

export default class Room {
  id: string
  @observable name: string
  @observable description: string
  @observable directory_id: string
  @observable dir_floor_ids: string[] = observable.array()
  @observable dir_file_ids: string[] = observable.array()
  @observable dir_tags: string[] = observable.array()
  @observable dir_location_ids: string[] = observable.array()
  _root: RootStore

  constructor(rootStore: RootStore, attrs: Dir_Room) {
    this._root = rootStore
    Object.assign(this, attrs)
  }

  @computed
  get images() {
    return this.dir_file_ids.map(id => API.getImgUrl(id))
  }

  @computed
  get floorOrder(): number | null {
    const dir = this._root.directoryStore.get(this.directory_id)
    const floor_id = this.dir_floor_ids.length ? this.dir_floor_ids[0] : null
    if (!dir || floor_id == null) return null
    const floor = dir.floorMap.get(floor_id)
    if (!floor) return null
    return floor.order
  }
}
