//TODO figure out how to add a picture to any given proffesor
import {observable, computed} from 'mobx'

import API from 'api/api'
import {Dir_Person} from 'api/interfaces'
import RootStore from 'stores/RootStore'
const DefaultAvatar = require('images/default_avatar.png')

/** TEMPORARY SORT-NAME HANDLING */
const ignoreSuffixes = Object.freeze(['jr', 'jr.', 'sr', 'sr.', 'esq', 'esq.'])
function getSortName(display_name: string) {
  display_name = display_name.trim()
  const names = display_name.split(/\s+/)
  if (names.length < 2) return display_name
  const last = names[names.length - 1].toLowerCase()
  // ignore prefixes
  if (ignoreSuffixes.indexOf(last) !== -1 || last.startsWith('ii')) {
    return names[names.length - 2].toLowerCase()
  }
  return last
}

export interface TimeSlot {
  start: string
  end: string
}

export default class Faculty {
  id: string
  @observable title: string
  @observable name: string
  @observable sortName: string
  @observable position: string
  @observable type: string
  @observable display_name: string
  @observable room_id: string
  @observable phone: string
  @observable email: string
  @observable description: string
  @observable hours: string
  @observable directory_id: string
  @observable imageOLD: string
  @observable image_id: string
  @observable dir_event_ids: string[] = observable.array()
  @observable vip: boolean
  _root: RootStore

  constructor(rootStore: RootStore, attrs: Dir_Person) {
    attrs.phone = attrs.phone || ''
    attrs.email = attrs.email || ''
    attrs.hours = attrs.hours || ''
    const overrides = {
      sortName: getSortName(attrs.display_name),
      imageOLD: attrs.image,
      image_id: attrs.dir_file_ids.length ? attrs.dir_file_ids[0] : null
    }
    delete attrs.image

    this._root = rootStore
    Object.assign(this, attrs, overrides)
  }

  @computed
  get roomname() {
    return this.room ? this.room.name : ''
  }

  @computed
  get room() {
    return this._root.roomStore.get(this.room_id) || null
  }

  @computed
  get image() {
    if (this.image_id) return API.getImgUrl(this.image_id)
    return this.imageOLD || DefaultAvatar
  }

  @computed
  get events() {
    return this.dir_event_ids.map(id => this._root.eventStore.get(id))
  }
}
