import * as React from 'react'
import {observer} from 'mobx-react'

import {Event, Faculty, Room} from 'models'
import {SearchableType, FuseResult} from 'stores/BaseStore'
import EventCard from 'modules/events/EventCard'
import PersonCard from 'modules/directory/PersonCard'
import RoomCard from 'modules/rooms/RoomCard'

interface Props {
  result: FuseResult<SearchableType>
  open: (thing: Event | Faculty) => void
}

const SearchResult: React.StatelessComponent<Props> = (props: Props) => {
  if (!props.result || !props.result.item) return null

  const {item, score} = props.result

  if (item instanceof Room) return <RoomCard room={item} data-score={score} />

  if (item instanceof Event)
    return (
      <EventCard event={item} score={score} open={() => props.open(item)} />
    )

  if (item instanceof Faculty)
    return (
      <PersonCard person={item} score={score} open={() => props.open(item)} />
    )

  console.error('Unknown search result:', props.result)
  return <div className="card">Error</div>
}

export default SearchResult
