import {observable, computed} from 'mobx'

import {Dir_Stair} from 'api/interfaces'
import Directory from './Directory'

/**
 * This should NOT be confused with the Dir_Stair.
 * A Staircase is the combination of a sequence of Dir_Stairs.
 */
export default class Staircase {
  readonly directory: Directory
  readonly floorToLocationMap: ReadonlyMap<string, string>
  readonly dir_floor_ids: ReadonlyArray<string>
  readonly dir_location_ids: ReadonlyArray<string>

  constructor(directory: Directory, stairs: Dir_Stair[]) {
    this.directory = directory
    const m = new Map(stairs.map(_stairToDestTuple))
    this.floorToLocationMap = m
    this.dir_floor_ids = [...m.keys()]
    this.dir_location_ids = [...m.values()]
  }

  /**
   * Alternate constructor for Staircase.
   * Takes in ALL the stairs in a building and finds the staircases.
   */
  static stairsToStaircases(D: Directory, allStairs: Dir_Stair[]) {
    const sets = generateDistinctSets(allStairs.map(_stairToLocIdTuple))
    // initialize groups as empty arrays, positionally mapping to `sets`
    const stairGroups: Array<Dir_Stair[]> = [...Array(sets.length)].map(_ => [])
    // for each Dir_Stair, find its set, then add it to corresponding group
    for (const stair of allStairs)
      for (const i_S in sets) {
        if (sets[i_S].has(stair.location_id)) {
          stairGroups[i_S].push(stair)
          break
        }
      }
    return stairGroups.map(group => new Staircase(D, group))
  }
}

/*
 * Helper functions
 */
function _stairToDestTuple(stair: Dir_Stair): [string, string] {
  return [stair.destination_floor_id, stair.destination_location_id]
}

function _stairToLocIdTuple(stair: Dir_Stair): [string, string] {
  return [stair.location_id, stair.destination_location_id]
}

/**
 * Take a sequence of pairs of values and split them into distinct groups
 * of values.
 */
function generateDistinctSets(pairs: [string, string][]) {
  // sort by ids for consistency
  pairs.sort((a, b) => +a[0] - +b[0])

  // divide into distinct sets
  let sets: Array<Set<string>> = []

  pairs.forEach(([p1, p2]) => {
    // check each set to find the missing p's
    for (const i_S in sets) {
      let S = sets[i_S]
      if (!S) continue // in case we've already wiped this set

      const has1 = S.has(p1),
        has2 = S.has(p2)
      if (has1 && has2) return // this pair is already handled
      if (has1 || has2) {
        // determine which one one is known and which is new
        const P_found = has1 ? p1 : p2
        const P_other = has2 ? p1 : p2

        for (let j_S = +i_S + 1; j_S < sets.length; j_S++) {
          let S2 = sets[j_S]
          if (S2 && S2.has(P_other)) {
            // S and S2 are joined by this pair
            sets[i_S] = new Set([...S, ...S2])
            sets[j_S] = null
            return
          }
        }
        // P_other wasn't in any other sets, so add it to S
        S.add(P_other)
        return
      }
    }
    // neither p1 nor p2 was in any of the sets; create a new one
    sets.push(new Set([p1, p2]))
  })

  return sets.filter(Boolean) // filter any remaining nulls
}

;(window as any).generateDistinctSets = generateDistinctSets
