import * as React from 'react'
import {computed} from 'mobx'
import {observer, inject} from 'mobx-react'
import {Button, Image} from 'react-bootstrap'
import * as moment from 'moment'

import Event from 'models/Event'

interface Props {
  event: Event
  score?: number
  open: () => void
}

function ordinalize(mome: moment.Moment) {
  // Format to ordinal (e.g. 5th) then split the suffix into a <sup> tag
  const t = mome.format('Do')
  return (
    <span className="event-ordinal">
      {t.substr(0, t.length - 2)}
      <sup>{t.substr(-2)}</sup>
    </span>
  )
}

const EventCard: React.StatelessComponent<Props> = (props: Props) => {
  const event = props.event
  return (
    <li
      className="card event-card"
      data-id={event.id}
      data-score={props.score}
      onClick={props.open}
    >
      <div className="event-name">
        <span>{event.name}</span>
      </div>
      <div className="vm-bar">
        {event.multiday ? (
          <div className="event-day">
            {ordinalize(event.start)}
            {' – '}
            {ordinalize(event.end)}
          </div>
        ) : (
          <div className="event-day">{ordinalize(event.start)}</div>
        )}
        <Button bsSize="large" className="vm-button" onClick={props.open}>
          VIEW MORE
        </Button>
      </div>
    </li>
  )
}

export default EventCard
