import * as React from 'react'

import Floor from 'models/Floor'

interface Props {
  floor: Floor
  open: () => void
  isSelected: boolean
}

const FloorCard: React.SFC<Props> = props => {
  return (
    <li
      className="card floor-card"
      data-id={props.floor.id}
      data-selected={props.isSelected}
      onClick={props.open}
    >
      <div className="floor-name">{props.floor.name}</div>
      <img className="floor-photo" src={props.floor.image} />
    </li>
  )
}

export default FloorCard
