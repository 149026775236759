import * as React from 'react'
import {AxiosError} from 'axios'
import {observer, inject} from 'mobx-react'
import {computed} from 'mobx'
import {withRouter, RouteComponentProps} from 'react-router'
import {ButtonToolbar, ButtonGroup, Button, Glyphicon} from 'react-bootstrap'

import {Directory, Event, Faculty} from 'models'
import {
  EventStore,
  RoomStore,
  FacultyStore,
  DirectoryStore,
  UIStore
} from 'stores'
import {getUniqueID} from 'stores/BaseStore'
import SearchResult from './SearchResult'
import FacultyModal from 'modules/directory/FacultyModal'
import EventModal from 'modules/events/EventModal'
import './styles/search'

interface MatchParams {
  dirID?: string
}

interface WrapperProps extends RouteComponentProps<MatchParams> {
  DirectoryStore: DirectoryStore
}

interface WrapperState {
  currentDirectory: Directory
  message: string
}

@inject('DirectoryStore')
@observer
export default class SearchPageDirectoryWrapper extends React.Component<
  WrapperProps,
  WrapperState
> {
  constructor(props: WrapperProps) {
    super(props)

    this.state = {
      currentDirectory: null,
      message: ''
    }
  }

  @computed
  get isGlobalSearch() {
    return this.props.match.path === '/touch/search'
  }

  componentDidUpdate(prevProps: WrapperProps) {
    if (prevProps.match.params.dirID !== this.props.match.params.dirID)
      this.load()
  }

  componentDidMount() {
    this.load()
  }

  load = () => {
    if (this.isGlobalSearch) return

    const dirID = this.props.match.params.dirID
    if (isNaN(parseInt(dirID)) || +dirID < 1) {
      this.errorHandler(dirID, 'Bad Directory ID')
      return
    }
    this.props.DirectoryStore.loadDirectory(dirID)
      .then(() => {
        this.setState({
          currentDirectory: this.props.DirectoryStore.get(dirID),
          message: ''
        })
      })
      .catch(this.errorHandler.bind(this, dirID))
  }

  errorHandler = (dirID: string, reason: Error | AxiosError | string) => {
    console.error(`SearchPage.load: dirID=${dirID}, reason=`, [reason])
    let message = 'unknown error'

    if (!reason) {
    } // skip if statement
    else if (typeof reason == 'string') message = reason
    else if ('response' in reason) message = reason.message

    this.setState({
      currentDirectory: null,
      message: `Failed to load directory ${dirID}; ${message}`
    })
  }

  render() {
    return this.state.currentDirectory || this.isGlobalSearch ? (
      <SearchPageWithRouter
        directory={this.state.currentDirectory}
        isGlobalSearch={this.isGlobalSearch}
      />
    ) : (
      <div className="error-search-wrapper">{this.state.message}</div>
    )
  }
}

interface Props extends RouteComponentProps<MatchParams> {
  EventStore?: EventStore
  RoomStore?: RoomStore
  FacultyStore?: FacultyStore
  UIStore?: UIStore
  directory: Directory | null
  isGlobalSearch: boolean
}

interface State {
  selectedEvent: Event | null
  selectedPerson: Faculty | null
}

@inject('EventStore', 'RoomStore', 'FacultyStore', 'UIStore')
@observer
class SearchPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedEvent: null,
      selectedPerson: null
    }
  }

  @computed
  get placeholder() {
    if (this.props.UIStore.searchResults.length > 0) return null
    // there are no search results
    return (
      <div className="search-result-placeholder">
        <p>
          {this.props.UIStore.searchTerm.length
            ? 'No results'
            : 'Search field is empty'}
        </p>
        <p>Enter new search or press home button</p>
      </div>
    )
  }

  open = (thing?: Event | Faculty) => {
    let event = null,
      person = null
    if (thing instanceof Event) event = thing
    else if (thing instanceof Faculty) person = thing
    this.setState({selectedEvent: event, selectedPerson: person})
  }

  render() {
    return (
      <div className="card-dir-wrap search-dir-wrap">
        {/*
        <ButtonToolbar className="card-dir-controls">
          <ButtonGroup bsSize="large">
            <Button>A</Button>
            <Button>Future</Button>
            <Button>Button</Button>
          </ButtonGroup>
        </ButtonToolbar>
        */}
        <div className="card-directory search-directory">
          <ul className="card-list-grid">
            {this.props.UIStore.searchResults.map(res => (
              <SearchResult
                result={res}
                open={this.open}
                key={getUniqueID(res)}
              />
            ))}
          </ul>
          {this.placeholder}
        </div>
        <EventModal event={this.state.selectedEvent} onClose={this.open} />
        <FacultyModal faculty={this.state.selectedPerson} onClose={this.open} />
      </div>
    )
  }
}

const SearchPageWithRouter = withRouter(SearchPage)
