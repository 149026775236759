import * as React from 'react'
import {observer} from 'mobx-react'
import {computed} from 'mobx'
import {Modal, Button, Glyphicon} from 'react-bootstrap'

import Faculty, {TimeSlot} from 'models/Faculty'
import TakeMeThere from 'modules/global/TakeMeThere'

import './styles/FacultyModal'

interface Props {
  faculty: Faculty | null
  onClose: () => void
}

interface State {}

@observer
export default class FacultyModal extends React.Component<Props, State> {
  @computed
  get officeHours() {
    if (this.props.faculty.hours)
      return (
        <p className="fmbr-hours">
          <b>Office Hours: </b>
          <span>{this.props.faculty.hours}</span>
        </p>
      )
    return null
  }

  getText = (field: keyof Faculty, prefix = 'fmbl') => {
    const data = this.props.faculty[field]

    if (!data) return null
    return <p className={`${prefix}-${field}`}>{data}</p>
  }

  @computed
  get bottomClasses() {
    const {position, description, roomname, hours} = this.props.faculty
    let class_ = ''
    if (roomname || hours) class_ = 'fmb-only-right'
    if (position || description) {
      if (class_) return 'fmb-both'
      return 'fmb-only-left'
    }
    return class_ || 'fmb-none'
  }

  render() {
    if (!this.props.faculty) return null

    return (
      <Modal
        className="faculty-modal"
        show={this.props.faculty !== null}
        onHide={this.props.onClose}
      >
        <div className="fm-top">
          <div className="faculty-info">
            <div>
              <span className="faculty-name">
              {this.props.faculty.title} {this.props.faculty.display_name}
              </span>
              <span className="faculty-title">{this.props.faculty.position}</span>
            </div>

            <div>
              <span className="faculty-email">{this.props.faculty.email}</span>
              <span className="faculty-phone">{this.props.faculty.phone}</span>
            </div>
          </div>
          <img className="faculty-img" src={this.props.faculty.image} />
          <span className="faculty-type">{this.props.faculty.type}</span>
        </div>
        <div className={`fm-bottom ${this.bottomClasses}`}>
          <div className="fm-footer">
            <div id={"info"}>
              {this.getText('roomname', 'fmbr')}
              {this.officeHours}
            </div>
            <div id={"button"}>
              <TakeMeThere thing={this.props.faculty} />
            </div>
          </div>
          <div className="fm-body">
            {this.getText('position')}
            {this.getText('description')}
          </div>
        </div>
        <Button className="close-button" onClick={this.props.onClose}>
          <Glyphicon glyph="remove-circle" />
        </Button>
      </Modal>
    )
  }
}
