import {observable, computed} from 'mobx'

import {Dir_Location} from 'api/interfaces'
import RootStore from 'stores/RootStore'

//prettier does not format decorators in a neat way
/* prettier-ignore */

export default class Location implements Dir_Location {
  id: string
  @observable name: string
  @observable x: string
  @observable y: string
  @observable type: string
  @observable directory_id: string
  @observable room_id: string
  @observable floor_id: string
  @observable dir_neighbor_ids: string[]
  _root: RootStore

  @computed get neighbors() {
    return this.dir_neighbor_ids.map(id => this._root.locationStore.get(id))
  }

  @computed get room() {
    return this._root.roomStore.get(this.room_id)
  }

  constructor(rootStore: RootStore, attrs: Dir_Location) {
    this._root = rootStore
    Object.assign(this, attrs)
  }
}
