import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {RouteComponentProps} from 'react-router-dom'
import IdleTimer from 'react-idle-timer'

import {UIStore, DirectoryStore} from 'stores'

// duration of the idle transition after which redirect occurs
const IDLE_TRANSITION_TIMEOUT = 10

interface MatchParams {}

interface Props extends RouteComponentProps<MatchParams> {
  UIStore: UIStore
  DirectoryStore?: DirectoryStore
  url: string
}

interface State {
  idling: boolean
  transitionTimer: NodeJS.Timer | null
}

@inject('UIStore', 'DirectoryStore')
@observer
export default class InactivityWrapper extends React.Component<Props, State> {
  refs: {
    idleTimer: IdleTimer
  }

  state: State = {
    idling: false,
    transitionTimer: null
  }

  componentDidMount() {
    if (this.enabled) this.refs.idleTimer.resume()
  }

  @computed
  get enabled() {
    return (
      this.props.UIStore.inactivityTimeout >= IDLE_TRANSITION_TIMEOUT * 1000
    )
  }

  @computed
  get timeoutAmount() {
    const {inactivityTimeout} = this.props.UIStore
    if (this.enabled) {
      return inactivityTimeout - IDLE_TRANSITION_TIMEOUT * 1000
    } else {
      return Infinity
    }
  }

  @computed
  get coverStyle() {
    return {
      animationDuration: `${IDLE_TRANSITION_TIMEOUT}s`
    }
  }

  reset = () => {
    global.clearTimeout(this.state.transitionTimer)
    this.setState({
      idling: false,
      transitionTimer: null
    })
  }

  onActive = (e?: any) => {
    if (this.state.idling || this.state.transitionTimer) {
      this.reset()
    }
  }

  onIdle = () => {
    if (!this.enabled) {
      this.refs.idleTimer.pause()
      return
    }
    if (this.props.location.pathname === this.props.url) {
      /// still on the start-page
      this.props.UIStore.globalLoading++
      global.setTimeout(this.refetch, 100)
      return
    }

    this.setState({
      idling: true,
      transitionTimer: global.setTimeout(
        this.onIdleTransition,
        IDLE_TRANSITION_TIMEOUT * 1000
      )
    })
  }

  onIdleTransition = () => {
    if (this.props.location.pathname !== this.props.url) {
      this.reset()
      this.props.history.push(this.props.url)
      ///
      this.props.UIStore.globalLoading++
      this.props.UIStore.keyboardYPosition = window.innerHeight - 389
      this.props.UIStore.keyboardIsVisible = false
      document.getElementById('the-search-bar').blur()
      global.setTimeout(this.refetch, 50)
    }
  }

  refetch = () => {
    /// re-fetch directory data
    const {currentDirectory} = this.props.DirectoryStore
    if (currentDirectory && currentDirectory.id) {
      this.props.DirectoryStore.fetch(currentDirectory.id, true).then(
        () => this.props.UIStore.globalLoading--
      )
    } else {
      this.props.UIStore.globalLoading--
    }
  }

  render() {
    if (!this.enabled) return null

    return (
      <IdleTimer
        ref="idleTimer"
        timeout={this.timeoutAmount}
        onIdle={this.onIdle}
        onActive={this.onActive}
        startOnMount={true}
      >
        {this.state.idling ? (
          <div tabIndex={-1} id="idle-timeout-cover" style={this.coverStyle} />
        ) : null}
      </IdleTimer>
    )
  }
}
