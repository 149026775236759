import * as React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import MainComponent from 'modules/global/Main'
import CatchPage from 'modules/directory/404page'
import CampusMap from 'modules/directory/CampusMap'

export default () => (
  <Router>
    <Switch>
      <Route path="/touch/directory/:dirID" component={MainComponent} />
      <Route path="/touch/" component={MainComponent} />
      <Route path="/touch/campusmap/" component={CampusMap} />
      <Route component={CatchPage} />
    </Switch>
  </Router>
)
