import * as React from 'react'
import {inject} from 'mobx-react'

import UIStore from 'stores/UIStore'
const ISA = require('images/isa.svg')

interface Props {
  UIStore?: UIStore
}

@inject('UIStore')
export default class AccessibilityButton extends React.Component<Props> {
  onClick = () => this.props.UIStore.goAccessible()

  render() {
    return (
      <button id="accessibility-button" type="button" onClick={this.onClick}>
        {/*<Glyphicon glyph="collapse-down" />*/}
        {/*}<span className="accessibility-isa">{'\u267f'}</span>*/}
        <img className="accessibility-isa" src={ISA} />
      </button>
    )
  }
}
