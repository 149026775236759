import * as React from 'react'
import {observer} from 'mobx-react'
import {computed} from 'mobx'
import {Modal, Button, Glyphicon} from 'react-bootstrap'

import Event from 'models/Event'
import TakeMeThere from 'modules/global/TakeMeThere'
import EventPersonSubcard from './EventPersonSubcard'

import './styles/EventModal'

interface Props {
  event: Event | null
  onClose: () => void
}

@observer
export default class EventModal extends React.Component<Props> {
  get bottomClasses() {
    // not computed for efficiency
    if (!this.props.event || this.props.event.description) return 'fmb-both'
    return 'fmb-only-right'
  }

  @computed
  get times() {
    const event = this.props.event
    if (event.multiday)
      return (
        <div className="fmbr-times with-dates">
          <p>{event.start.format('MMM D, h:mm a')} - {event.end.format('MMM D, h:mm a')}</p>
        </div>
      )
    if (event.start.isSame(event.end))
      return (
        <div className="fmbr-times">
          <p>{event.start.format('h:mm a')}</p>
        </div>
      )
    return (
      <div className="fmbr-times">
        <p>{event.start.format('h:mm a')} - {event.end.format('h:mm a')}</p>
      </div>
    )
  }

  getText = (field: keyof Event, prefix = 'fmbl') => {
    const data = this.props.event[field]

    if (!data) return null
    return <p className={`${prefix}-${field}`}>{data}</p>
  }

  render() {
    if (!this.props.event) return null

    return (
      <Modal
        className="event-modal"
        show={this.props.event !== null}
        onHide={this.props.onClose}
      >
        <div className="fm-top">
          <span className="event-name">{this.props.event.name}</span>
        </div>
        <div className={`fm-bottom ${this.bottomClasses}`}>
          <div className="fm-footer">
            <div id={"info"}>
              {this.getText('roomname', 'fmbr')}
              {this.times}
              {this.props.event.persons.length ? (
                <ul className="fmbr-people">
                  {this.props.event.persons.map(P => (
                    <EventPersonSubcard person={P} key={P.id} />
                    ))}
                </ul>
              ) : null}
            </div>
            <div id={"button"}>
              <TakeMeThere thing={this.props.event} />
            </div>
          </div>
          <div className="fm-body">
            {this.getText('description')}
          </div>
        </div>
        <Button className="close-button" onClick={this.props.onClose}>
          <Glyphicon glyph="remove-circle" />
        </Button>
      </Modal>
    )
  }
}
