import DirectoryStore from './DirectoryStore'
import FacultyStore from './FacultyStore'
import EventStore from './EventStore'
import RoomStore from './RoomStore'
import LocationStore from './LocationStore'
import UIStore from './UIStore'
import ScreenStore from './ScreenStore'

export default class RootStore {
  directoryStore: DirectoryStore
  facultyStore: FacultyStore
  eventStore: EventStore
  roomStore: RoomStore
  locationStore: LocationStore
  uiStore: UIStore
  screenStore: ScreenStore

  constructor() {
    this.directoryStore = new DirectoryStore(this)
    this.facultyStore = new FacultyStore(this)
    this.eventStore = new EventStore(this)
    this.roomStore = new RoomStore(this)
    this.locationStore = new LocationStore(this)
    this.uiStore = new UIStore(this)
    this.screenStore = new ScreenStore(this)
  }
}
