import * as React from 'react'
import {Table, Glyphicon} from 'react-bootstrap'

import {Faculty as Person} from 'models'

import './styles/PersonTable'

interface Props {
  persons: Person[]
  goto: (facultyId?: string) => void
}

export default class PersonTable extends React.Component<Props> {
  missingHelper = (value: string) => {
    if (!value || value.indexOf('Email unavailable.') === 0) return 'missing'
    return ''
  }

  render() {
    return (
      <Table responsive bordered condensed striped className="person-list-row">
        <thead>
          <tr>
            <th>Type</th>
            <th>
              <Glyphicon glyph="picture" />
            </th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Room</th>
          </tr>
        </thead>
        <tbody>
          {this.props.persons.map(f => (
            <tr key={f.id} data-id={f.id} onClick={() => this.props.goto(f.id)}>
              <td>{f.type}</td>
              <td>
                <img className="person-photo" src={f.image} />
              </td>
              <td>
                {f.title ? `${f.title} ${f.display_name}` : f.display_name}
              </td>
              <td className={this.missingHelper(f.email)}>{f.email}</td>
              <td className={this.missingHelper(f.phone)}>{f.phone}</td>
              <td>{f.roomname}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}
