import {observable, ObservableMap, values} from 'mobx'
import RootStore from './RootStore'

import Event from 'models/Event'
import Faculty from 'models/Faculty'
import Room from 'models/Room'

export type SearchableType = Event | Faculty | Room

export interface FuseResult<T> {
  item?: T
  score?: number
}

interface Model extends Object {
  id: string
}

export type SearchFunction<T> = (inputValue: string) => FuseResult<T>[]

export function getUniqueID(thing: any): string {
  if (!thing || !thing.item) return `undefined{Math.random()}`
  return `${thing.item.constructor.name}-${Math.random()}`.toLowerCase()
}

export default abstract class BaseStore<T extends Model> {
  @observable data: ObservableMap<string, T> = observable.map()
  root: RootStore

  static readonly _searchOptions = Object.freeze({
    caseSensitive: false,
    includeScore: true,
    shouldSort: true,
    distance: Infinity,
    threshold: 0.1
  })

  constructor(root: RootStore) {
    this.root = root
    // the function `get` cannot be set as an arrow
    this.get = this.get.bind(this)
  }

  abstract readonly search: SearchFunction<T>

  get(id: string) {
    return this.data.get(id)
  }

  edit = (data: T, attrs: any) => {
    Object.assign(data, attrs)
  }

  delete = (model: T) => {
    this.data.delete(model.id)
  }
}
