import * as React from 'react'
import {computed, observable, ObservableMap} from 'mobx'
import {inject, observer} from 'mobx-react'
import {RouteComponentProps} from 'react-router'
import Select from 'react-select'

import withDirectoryWrapper from 'modules/global/DirectoryWrapper'
import FloorPlan, {buildFloorPlan} from '../FloorPlan'
import MultiFloorPathFinderInner from './MultiFloorPathFinderInner'
import FloorCard from '../FloorCard'
import {DirectoryStore, LocationStore, RoomStore} from 'stores'
import {Location, Floor, Directory} from 'models'

interface MatchParams {
  dirID: string
  locID?: string
}

interface WrapperProps extends RouteComponentProps<MatchParams> {
  directory: Directory
  LocationStore: LocationStore
  DirectoryStore: DirectoryStore
  RoomStore: RoomStore
}

interface WrapperState {
  selectedFloorID: string | null
}

@inject('LocationStore', 'RoomStore')
@observer
class MFPFWrapper extends React.Component<WrapperProps, WrapperState> {
  constructor(props: WrapperProps) {
    super(props)

    this.state = {
      selectedFloorID: this.startingLocation
        ? this.startingLocation.floor_id
        : null
    }
  }

  handleSelectLocation = (e: any) => {
    const roomID = e.value
    const room = this.props.RoomStore.get(roomID) || null
    let locID = ''
    if (room && room.dir_location_ids.length) locID = room.dir_location_ids[0]
    this.setState({selectedFloorID: null})

    this.props.history.push(
      this.props.match.path
        .replace(':dirID', room.directory_id)
        .replace(':locID?', locID)
    )
  }

  handleSelectFloor = (floorID: string) => {
    if (floorID !== this.state.selectedFloorID)
      this.setState({
        selectedFloorID: this.props.directory.floorMap.has(floorID)
          ? floorID
          : null
      })
  }

  @computed
  get startingLocation() {
    const {screenLocation} = this.props.LocationStore
    const currentDirectory = this.props.directory

    // if the current directory contains the current screen, use it
    if (screenLocation && screenLocation.directory_id == currentDirectory.id)
      return screenLocation
    // use ANY screen from the current directory, else null
    if (currentDirectory.screens.length)
      return currentDirectory.screens[0].location
    return null
  }

  @computed
  get destinationLocation() {
    return this.props.LocationStore.get(this.props.match.params.locID) || null
  }

  @computed
  get destinationRoom() {
    return this.destinationLocation ? this.destinationLocation.room : null
  }

  @computed
  get currentFloorLocations() {
    // exit if destinationLocation (or its floor_id) is falsy
    const floorID = (this.destinationLocation || ({} as any)).floor_id
    if (!floorID) return []

    return this.props.directory.locations.filter(
      loc => loc.floor_id === floorID
    )
  }

  @computed
  get locationMap() {
    return observable.map<string, Location>(
      this.props.directory.locations.map(
        loc => [loc.id, loc] as [string, Location]
      )
    )
  }

  @computed
  get floorplans(): FloorPlan[] {
    const {directory} = this.props

    // for each floor, generate a floormap
    return directory.floors.map(floor =>
      buildFloorPlan(floor, directory, this.startingLocation)
    )
  }

  @computed
  get selectedFloor() {
    let id = this.state.selectedFloorID
    const SL = this.startingLocation
    const D = this.props.directory
    if (!id) {
      if (SL && SL.floor_id) id = this.startingLocation.floor_id
      else if (D.dir_floor_ids.length)
        id = this.props.directory.dir_floor_ids[0]
      else return null
    }
    return this.props.directory.floorMap.get(id)
  }

  @computed
  get sortedOptions() {
    return this.props.directory.rooms
      .map(room => ({
        value: room.id,
        label: room.name,
        floorOrder: room.floorOrder
      }))
      .sort((a, b) =>
        a.floorOrder == b.floorOrder
          ? a.label.localeCompare(b.label)
          : a.floorOrder - b.floorOrder
      )
  }

  @computed
  get value() {
    const room = this.destinationRoom
    if (room)
      return {
        value: room.id,
        label: room.name,
        floorOrder: this.props.directory.floorMap.get(
          room.dir_floor_ids.length ? room.dir_floor_ids[0] : null
        ).order
      }
    return undefined
  }

  /** render helpers */

  get pf_message() {
    let msg = ''
    if (!this.props.directory) msg = 'Critical error: no directory'
    else if (!this.props.directory.dir_floor_ids.length) msg = 'No floors'
    else if (!this.props.directory.dir_location_ids.length) msg = 'No locations'
    else if (!this.startingLocation) msg = 'No associated Touch screens'
    else if (
      this.props.match.params.locID &&
      !(this.destinationLocation || this.destinationRoom)
    )
      msg = 'Missing data on destination'

    return msg
  }

  render() {
    return (
      <div className="pf-wrapper">
        <div className="pf-left">
          <div className="pf-left-top">
            <div className="pf-selector-wrapper">
              <Select
                onChange={this.handleSelectLocation}
                options={this.sortedOptions}
                isDisabled={this.props.directory.rooms.length === 0}
                className="pf-selector"
                classNamePrefix="react-select"
                placeholder="Find a location..."
                value={this.value}
              />
            </div>
            <div className="pf-message">{this.pf_message}</div>
          </div>

          <div className="pf-left-bottom">
            <MultiFloorPathFinderInner
              LocationStore={this.props.LocationStore}
              destination={this.destinationLocation}
              from={this.startingLocation}
              floorplans={this.floorplans}
              selectedFloor={this.selectedFloor}
            />
          </div>
        </div>
        <div className="pf-right">
          <div className="pf-floor-selector">
            {this.props.directory.floors.map(floor => (
              <FloorCard
                key={floor.id}
                data-id={floor.id}
                floor={floor}
                open={() => this.handleSelectFloor(floor.id)}
                isSelected={floor === this.selectedFloor}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withDirectoryWrapper(MFPFWrapper)
