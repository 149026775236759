import * as React from 'react'
import {Panel, Glyphicon} from 'react-bootstrap'

import Person from 'models/Faculty'
import PersonCard from './PersonCard'
import './styles/VipRow.scss'

interface Props {
  persons: Person[]
  goto: (personId?: string) => void
}

export default class VipRow extends React.Component<Props> {
  render() {
    const hide = this.props.persons.length ? '' : 'hidden'
    return (
      <Panel className={`vip-row-panel ${hide}`}>
        <Panel.Heading>VIPs</Panel.Heading>
        <Panel.Body>
          {this.props.persons.map(p => (
            <PersonCard
              key={p.id}
              person={p}
              open={() => this.props.goto(p.id)}
            />
          ))}
        </Panel.Body>
      </Panel>
    )
  }
}
