import {Location, Staircase, Floor, Directory} from 'models'

export default interface FloorPlan {
  floor: Floor
  start: string
  destinations: Location[]
  stairs: Staircase[]
}

export function buildFloorPlan(F: Floor, D: Directory, S: Location) {
  const destinations = D.locations.filter(L => L.floor_id === F.id)
  const stairs = D.staircases.filter(sc => sc.dir_floor_ids.indexOf(F.id) >= 0)

  let sid = null
  if (S) sid = S.id
  else
    for (const scrn of D.screens)
      if (scrn && scrn.location.floor_id === F.id) {
        sid = scrn.id
        break
      }

  return {
    floor: F,
    start: sid,
    destinations,
    stairs
  }
}
