import * as React from 'react'
import {observer} from 'mobx-react'

import DirectoryStore from 'stores/DirectoryStore'

interface Props {
  show: boolean
  DirectoryStore: DirectoryStore
}

/**
 * If `show` and `DirectoryStore.currentDirectory` has a description
 * or image, display it.
 */
const HeaderWelcome: React.SFC<Props> = props => {
  const {currentDirectory} = props.DirectoryStore
  if (!props.show || !currentDirectory) return null
  const {description, image} = currentDirectory

  return (
    <div className="header-welcome">
      {description && <p>{description}</p>}
      {image && <img className="dir-logo" src={image} />}
    </div>
  )
}

export default observer(HeaderWelcome)
