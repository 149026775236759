import * as React from 'react'
import * as moment from 'moment'
import {observer, inject} from 'mobx-react'
import {computed} from 'mobx'

import EventStore from 'stores/EventStore'
const BigCalendar = require('react-big-calendar')
import 'modules/calendar/styles/calendar'

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment))

interface Props {
  EventStore: EventStore
}

interface CalendarEvent {
  start: string
  end: string
  title: string
}

@inject('EventStore')
@observer
export default class Calendar extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  @computed
  get events() {
    return this.props.EventStore.events.map(event => {
      console.info('hi')
      return {
        start: new Date(event.start.format()),
        end: new Date(event.end.format()),
        title: event.name
      }
    })
  }

  render() {
    return (
      <div className="calendarbox">
        <BigCalendar
          defaultDate={moment().toDate()}
          defaultView="month"
          events={this.events}
          style={{height: '70vh', width: '70vh'}}
        />
      </div>
    )
  }
}
