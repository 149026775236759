import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {computed} from 'mobx'
import {RouteComponentProps} from 'react-router'
import {ButtonToolbar, ButtonGroup, Button, Glyphicon} from 'react-bootstrap'

import Person from 'models/Faculty'
import Directory, {FilterCriteria, FilterType} from 'models/Directory'
import {FacultyStore, UIStore} from 'stores'
import PersonCard from './PersonCard'
import PersonTable from './PersonTable'
import FacultyModal from './FacultyModal'
import VipRow from './VipRow'
import withDirectoryWrapper from 'modules/global/DirectoryWrapper'
import './styles/PersonCard'

interface Props extends RouteComponentProps<MatchParams> {
  directory: Directory
  FacultyStore?: FacultyStore
  UIStore?: UIStore
}

interface State {
  /// a string for simple filtering, the 'type' value to sort by
  simpleFilter: string
  /// complex filtering fields; also used for button state
  filterFaculty: boolean
  filterStaff: boolean
  filterSPECIAL: boolean[]
  filterOther: boolean
}

interface MatchParams {
  dirID: string
  facultyID?: string
}

@inject('FacultyStore', 'UIStore')
@observer
class FacultyDirectory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = this.defaultState
  }

  componentDidUpdate() {
    // if the length of the directory custom-type array has changed,
    // we need to update the `filterSPECIAL` array to the correct length
    if (
      this.props.directory.customType.length !== this.state.filterSPECIAL.length
    ) {
      this.setState({
        filterSPECIAL: this.props.directory.customType.map(() => false)
      })
    }
  }

  @computed
  get defaultState(): State {
    return {
      simpleFilter: '',
      filterFaculty: false,
      filterStaff: false,
      filterSPECIAL: this.props.directory.customType.map(() => false),
      filterOther: false
    }
  }

  /**
   * Whether or not to simple filtering.
   * If true: use the `simpleFilter` field to filter
   * If false: use the rest of the state variables to filter independently
   */
  @computed
  get useSimpleFilter(): boolean {
    return this.props.directory.useSimpleFilter
  }

  @computed
  get hasCustomType() {
    return !!this.props.directory.customType.length
  }

  @computed
  get needsOtherButton() {
    return this.props.directory.allFilterLabels.indexOf('other') !== -1
  }

  @computed
  get displayMode() {
    return this.props.location.hash.includes('row') ? 'row' : 'grid'
  }

  /**
   * The filter criteria used in complex filtering
   */
  @computed
  get filterBy(): FilterCriteria[] {
    const outputCriteria: FilterCriteria[] = []

    if (this.state.filterFaculty) outputCriteria.push('faculty')
    if (this.state.filterStaff) outputCriteria.push('staff')
    if (this.state.filterOther) outputCriteria.push('other')
    // iterate over filterSPECIAL to check which to add
    this.state.filterSPECIAL.forEach((doAdd, idx) => {
      if (doAdd) {
        outputCriteria.push(this.props.directory.customType[
          idx
        ] as FilterCriteria)
      }
    })

    return outputCriteria.length
      ? outputCriteria
      : this.props.directory.allFilterLabels
  }

  @computed
  get sortedPersons() {
    return this.props.directory.faculty.sort((a, b) => {
      if (a.sortName < b.sortName) return -1
      if (a.sortName > b.sortName) return 1
      return 0
    })
  }

  @computed
  get filteredPersons() {
    this.state /// make sure mobx knows filteredPersons depends on state.
    return this.sortedPersons.filter(
      this.useSimpleFilter
        ? this.simpleFilterFunction
        : this.complexFilterFunction
    )
  }

  @computed
  get filteredVips() {
    return this.filteredPersons.filter(person => person.vip)
  }

  @computed
  get selectedFaculty() {
    const id = this.props.match.params.facultyID
    return this.props.FacultyStore.get(id) || null
  }

  /**
   * Filter function to use if using simple filtering
   */
  simpleFilterFunction = (person: Person) => {
    const {searchTerm, searchResultIDs} = this.props.UIStore
    if (searchTerm && searchResultIDs.indexOf(person.id) === -1) {
      // a search term is entered and it does not match
      return false
    }
    if (this.state.simpleFilter === '') return true
    if (this.state.simpleFilter === 'other') {
      return this.props.directory.mainFilterValues.indexOf(person.type) === -1
    }
    return this.state.simpleFilter === person.type
  }

  /**
   * Filter function to use if NOT using simple filtering
   */
  complexFilterFunction = (person: Person) => {
    const {searchTerm, searchResultIDs} = this.props.UIStore
    if (searchTerm && searchResultIDs.indexOf(person.id) === -1) {
      // a search term is entered and it does not match
      return false
    }
    // type strictly matches filtered options
    if (this.filterBy.indexOf(person.type as FilterCriteria) !== -1) return true
    // type is other
    if (this.props.directory.mainFilterValues.indexOf(person.type) === -1) {
      return this.filterBy.indexOf('other') !== -1
    }
    return false
  }

  goto = (facultyID?: string) => {
    // Redirect to a faculty modal, or close one if ID is omitted
    this.props.history.push(
      this.props.match.path
        .replace(':dirID', this.props.match.params.dirID)
        .replace(':facultyID?', facultyID || '') + `#${this.displayMode}`
    )
  }

  handleModeChange = (mode: 'row' | 'grid') => {
    const loc = this.props.history.location
    if (mode === 'row') loc.hash = '#row'
    else loc.hash = ''
    this.props.history.push(this.props.history.location)
  }

  toggleFilter = (criteria: FilterCriteria) => {
    const stateProp: FilterType = ('filter' +
      criteria[0].toUpperCase() +
      criteria.slice(1)) as FilterType

    /// simple filtering
    if (this.useSimpleFilter) {
      const alreadySelected = this.state.simpleFilter === criteria
      this.setState({
        ...this.defaultState,
        simpleFilter: alreadySelected ? '' : criteria,
        [stateProp]: !alreadySelected
      })
    }
    /// complex filtering
    else {
      this.setState({
        ...this.state,
        [stateProp]: !this.state[stateProp]
      })
    }
  }

  /**
   * Toggle the filter state for the given filter criteria.
   * Since `filterSPECIAL` is an array, changing its contents won't trigger
   * a change of state, so we need to copy and reassign the state variable.
   */
  toggleFilterSPECIAL = (idx: number) => {
    if (!this.props.directory.customType) return
    if (idx >= this.state.filterSPECIAL.length) return

    /// simple filtering
    if (this.useSimpleFilter) {
      const defaulted = Object.assign({}, this.defaultState)
      const criteria = this.props.directory.customType[idx]
      const alreadySelected = this.state.simpleFilter === criteria

      defaulted.filterSPECIAL[idx] = !alreadySelected
      this.setState({
        ...defaulted,
        simpleFilter: alreadySelected ? '' : criteria
      })
    }
    /// complex filtering
    else {
      // copy and update
      const copyOfSPECIAL = this.state.filterSPECIAL.slice()
      copyOfSPECIAL[idx] = !copyOfSPECIAL[idx]

      this.setState({
        filterSPECIAL: copyOfSPECIAL
      })
    }
  }

  render() {
    return (
      <div className="card-dir-wrap faculty-dir-wrap">
        <ButtonToolbar className="card-dir-controls faculty-controls">
          <ButtonGroup bsSize="large" className="buttongroup-row-and-grid">
            <Button
              alt="Row Mode"
              className="button-row"
              active={this.displayMode !== 'row'}
              disabled={this.displayMode === 'row'}
              onClick={() => this.handleModeChange('row')}
            >
              <Glyphicon glyph="th-list" />
            </Button>
            <Button
              alt="Grid Mode"
              className="button-grid"
              active={this.displayMode !== 'grid'}
              disabled={this.displayMode === 'grid'}
              onClick={() => this.handleModeChange('grid')}
            >
              <Glyphicon glyph="picture" />
            </Button>
          </ButtonGroup>
          <ButtonGroup bsSize="large" className="faculty-filtering">
            <Button
              active={this.state.filterFaculty}
              onClick={() => this.toggleFilter('faculty')}
            >
              Faculty
            </Button>
            <Button
              active={this.state.filterStaff}
              onClick={() => this.toggleFilter('staff')}
            >
              Staff
            </Button>
            {this.hasCustomType
              ? this.props.directory.customType.map((customValue, idx) => (
                  <Button
                    active={this.state.filterSPECIAL[idx]}
                    onClick={() => this.toggleFilterSPECIAL(idx)}
                    key={customValue}
                  >
                    {customValue}
                  </Button>
                ))
              : null}
            {this.needsOtherButton ? (
              <Button
                active={this.state.filterOther}
                onClick={() => this.toggleFilter('other')}
              >
                Other
              </Button>
            ) : null}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="card-directory faculty-directory">
          <VipRow persons={this.filteredVips} goto={this.goto} />
          {this.filteredPersons.length ? (
            this.displayMode === 'grid' ? (
              <ul className="card-list-grid">
                {this.filteredPersons.map(f => (
                  <PersonCard
                    key={f.id}
                    person={f}
                    open={this.goto.bind(this, f.id)}
                  />
                ))}
              </ul>
            ) : (
              <PersonTable persons={this.filteredPersons} goto={this.goto} />
            )
          ) : (
            <div className="search-result-placeholder">
              <p>No people found</p>
              <p>Enter new search or press home button</p>
            </div>
          )}
        </div>
        <FacultyModal
          faculty={this.selectedFaculty}
          onClose={() => this.goto()}
        />
      </div>
    )
  }
}

export default withDirectoryWrapper(FacultyDirectory)
