import {computed, observable, ObservableMap} from 'mobx'
import {Location, Screen, Floor} from 'models'
import BaseStore, {FuseResult} from './BaseStore'
import {Dir_Location, Dir_Stair, EitherResponseData} from 'api/interfaces'

export default class LocationStore extends BaseStore<Location> {
  // TEMP: only supports one floor for RTD. Will support multiple floors later
  @observable floorName: string = 'Second Floor'

  @observable stairMap: ObservableMap<string, Dir_Stair> = observable.map()
  @observable floorMap: ObservableMap<string, Floor> = observable.map()
  @observable screenMap: ObservableMap<string, Screen> = observable.map()

  @computed
  get locations() {
    return Array.from(this.data.values())
  }

  @computed
  get stairs() {
    return Array.from(this.stairMap.values())
  }

  @computed
  get floors() {
    return Array.from(this.floorMap.values())
  }

  @computed
  get screenLocation() {
    const {currentScreen} = this.root.screenStore

    if (!currentScreen) return null
    return currentScreen.location
  }

  @computed
  get screenFloor() {
    const {screenLocation, floorMap} = this
    if (!screenLocation) return null
    return floorMap.get(screenLocation.floor_id) || null
  }

  locationsForFloor(id: string) {
    return this.locations.filter(location => location.floor_id === id)
  }

  search = (): FuseResult<Location>[] => []

  add = (attrs: Dir_Location) => {
    const newLocation = new Location(this.root, attrs)

    this.data.set(newLocation.id, newLocation)

    return newLocation
  }

  process = (data: EitherResponseData) => {
    data.floors.forEach(floor => {
      this.floorMap.set(floor.id, new Floor(this.root, floor))
    })
    data.stairs.forEach(stair => this.stairMap.set(stair.id, stair))
    data.locations.forEach(this.add)
  }
}
