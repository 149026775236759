import * as React from 'react'
import {computed} from 'mobx'
import {observer, inject} from 'mobx-react'
import * as moment from 'moment'
import {Glyphicon} from 'react-bootstrap'

import {Directory, Event} from 'models'
import {EventStore, UIStore} from 'stores'
import EventCard from './EventCard'

interface Props {
  UIStore?: UIStore
  directory: Directory
  yearmonth: string // 'YY/MM' format
  openModal: (event?: Event) => void
}

interface State {
  expanded: boolean
}

@inject('UIStore')
@observer
export default class MonthSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      expanded: true
    }
  }
  @computed
  get sortedEvents() {
    const events = this.props.directory.monthMap.get(this.props.yearmonth)
    if (!events) return []

    return events.filter(e => e.enabled).sort((a, b) => a.sortCmp(b))
  }

  @computed
  get filteredEvents() {
    const {searchTerm, searchResultIDs} = this.props.UIStore
    return this.sortedEvents.filter(
      event => !(searchTerm && searchResultIDs.indexOf(event.id) === -1)
    )
  }

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    if (!this.filteredEvents.length) return null

    const title = moment(this.props.yearmonth, 'YY/MM').format('MMMM YYYY')
    return (
      <section className="month-section" data-yearmonth={this.props.yearmonth}>
        <header onClick={this.toggle}>
          <span>{title}</span>{' '}
          <Glyphicon glyph={this.state.expanded ? 'collapse-up' : 'expand'} />
          <hr />
        </header>
        <ul className="card-list-grid">
          {this.state.expanded &&
            this.filteredEvents.map(f => (
              <EventCard
                key={f.id}
                event={f}
                open={() => this.props.openModal(f)}
              />
            ))}
        </ul>
      </section>
    )
  }
}
