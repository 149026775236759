import {computed} from 'mobx'

import {Dir_Room} from 'api/interfaces'
import Room from 'models/Room'
import BaseStore, {FuseResult} from './BaseStore'
import * as Fuse from 'fuse.js'

export default class RoomStore extends BaseStore<Room> {
  static readonly _searchOptions = Object.freeze(
    Object.assign(
      {
        keys: ['name', 'description']
      },
      BaseStore._searchOptions
    )
  )

  @computed
  get fuse() {
    return new Fuse(this.rooms, RoomStore._searchOptions)
  }

  @computed
  get rooms() {
    return Array.from(this.data.values())
  }

  search = (inputValue: string): FuseResult<Room>[] => {
    return this.fuse.search(inputValue)
  }

  add = (attrs: Dir_Room) => {
    const newRoom = new Room(this.root, attrs)

    this.data.set(newRoom.id, newRoom)

    return newRoom
  }

  process = (data: Dir_Room[]) => {
    for (const room of data) {
      this.add(room)
    }
  }
}
