import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Route, Switch, RouteComponentProps} from 'react-router-dom'

import Header from 'modules/global/Header'
import Footer from 'modules/global/Footer'
import BuildingDirectory from 'modules/directory/BuildingDirectory'
import FacultyDirectory from 'modules/directory/FacultyDirectory'
import EventDirectory from 'modules/events/EventDirectory'
import MainDirectory from 'modules/directory/MainDirectory'
import UIStore from 'stores/UIStore'
import MultiFloorPathFinder from 'modules/floors/MultiFloorPathFinder'
import CampusMap from 'modules/directory/CampusMap'
import Calendar from 'modules/calendar/calendar'
import SearchPage from './search/SearchPage'
import AccessibilityButton from './AccessibilityButton'
import InactivityWrapper from './inactivity/InactivityWrapper'
import GlobalLoadingSpinner from './inactivity/GlobalLoadingSpinner'

import './styles/main'
import './styles/BuildingCard'
import TouchscreenKeyboard from './TouchscreenKeyboard'

interface MatchParams {}

interface Props extends RouteComponentProps<MatchParams> {
  UIStore: UIStore
}

interface State {
  initialPathname: string
}

@inject('UIStore')
@observer
export default class MainComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      initialPathname: this.props.location.pathname
    }
  }

  componentDidMount() {
    this.props.UIStore.parseQueryString(this.props.location.search)
  }

  noRightClick = (e: React.SyntheticEvent) => e.preventDefault()

  render() {
    return (
      <div
        id="main-wrapper"
        onContextMenu={
          this.props.UIStore.rightClickDisabled ? this.noRightClick : undefined
        }
      >
        <Header />
        <style>{this.props.UIStore.directoryDynamicStyles}</style>
        <main id="main">
          <Switch>
            <Route
              exact
              path="/touch/directory/:dirID"
              component={BuildingDirectory}
            />
            <Route
              exact
              path="/touch/directory/:dirID/people/:facultyID?"
              component={FacultyDirectory}
            />
            <Route
              exact
              path="/touch/directory/:dirID/events/:eventID?"
              component={EventDirectory}
            />
            <Route
              path="/touch/directory/:dirID/floormap/:locID?"
              component={MultiFloorPathFinder}
            />
            <Route
              path="/touch/directory/:dirID/search"
              component={SearchPage}
            />
            <Route path="/touch/search" component={SearchPage} />
            <Route path="/touch/campusmap" component={CampusMap} />
            <Route path="/touch/calendar" component={Calendar} />
            <Route exact component={MainDirectory} />
          </Switch>
        </main>
        <Footer />
        <AccessibilityButton />
        <div
          id="accessibility-top-half"
          onClick={() => this.props.UIStore.goAccessible(false)}
        >
          Touch here or the accessibility icon to exit Accessibility Mode
        </div>
        <InactivityWrapper url={this.state.initialPathname} {...this.props} />
        <TouchscreenKeyboard UIStore={this.props.UIStore} />
        <GlobalLoadingSpinner UIStore={this.props.UIStore} />
      </div>
    )
  }
}
