import {observable, computed} from 'mobx'

import {Dir_Screen} from 'api/interfaces'
import RootStore from 'stores/RootStore'

export default class Screen {
  id: string
  @observable name: string
  @observable x: number
  @observable y: number
  @observable type: string
  @observable directory_id: string
  @observable location_id: string
  _root: RootStore

  constructor(rootStore: RootStore, attrs: Dir_Screen) {
    this._root = rootStore
    delete attrs['location']
    Object.assign(this, attrs, {
      id: String(attrs.id),
      directory_id: String(attrs.directory_id),
      location_id: String(attrs.location_id)
    })
  }

  @computed
  get location() {
    return this._root.locationStore.get(this.location_id)
  }
}
