import {observable, computed} from 'mobx'

import API from 'api/api'
import {Dir_Floor} from 'api/interfaces'
import RootStore from 'stores/RootStore'

export default class Floor {
  id: string
  @observable name: string
  @observable description: string
  @observable directory_id: string
  @observable image_id: string
  @observable order: number
  _root: RootStore

  constructor(rootStore: RootStore, attrs: Dir_Floor) {
    this._root = rootStore
    Object.assign(this, attrs)
    this.image_id = attrs.dir_file_ids[0]
  }

  @computed
  get directory() {
    return this._root.directoryStore.get(this.directory_id)
  }

  @computed
  get image() {
    return this.image_id ? API.getImgUrl(this.image_id) : null
  }
}
