import * as React from 'react'
import {computed} from 'mobx'
import {observer, inject} from 'mobx-react'
import {Button, Image} from 'react-bootstrap'

import Person from 'models/Faculty'

interface Props {
  person: Person
  score?: number
  open: () => void
}

const PersonCard: React.StatelessComponent<Props> = (props: Props) => {
  const person = props.person
  return (
    <li
      className="card person-card"
      data-id={person.id}
      data-score={props.score}
      data-vip={person.vip || undefined}
      onClick={props.open}
    >
      <div className="person-name">{person.display_name}</div>
      {person.type && <div className="person-type">{person.type}</div>}
      {person.roomname && <div className="person-room">{person.roomname}</div>}
      <img className="person-photo" src={person.image} />
      <div className="vm-bar">
        <Button bsSize="large" className="vm-button" onClick={props.open}>
          VIEW MORE
        </Button>
      </div>
    </li>
  )
}

export default PersonCard
