import * as React from 'react'
import {withRouter, RouteComponentProps} from 'react-router'

import Person from 'models/Faculty'

interface MatchParams {
  dirID: string
}

interface Props extends RouteComponentProps<MatchParams> {
  person: Person
}

class EventPersonSubcard extends React.Component<Props> {
  goto = () => {
    const P = this.props.person
    this.props.history.push(`/touch/directory/${P.directory_id}/people/${P.id}`)
  }

  render() {
    const person = this.props.person
    return (
      <li
        className="event-person-subcard"
        data-id={person.id}
        onClick={this.goto}
      >
        <img className="person-photo" src={person.image} />
        <div className="person-name">{person.display_name}</div>
      </li>
    )
  }
}

export default withRouter(EventPersonSubcard)
